<template>
  <Modal
    :stop-mobile-max-height="true"
    to-top
    @close="
      () => {
        router.push('/');
        $emit('close');
      }
    "
  >
    <Card
      :title="$t('popups.confirmMB.loginType')"
      :class="{ max: showMore }"
      class="login-modal"
    >
      <div v-if="!showMore" class="login-title-description">
        Trading derivatives carries high risk.
        <a
          class="decoration--underlined"
          rel="noopener noreferrer"
          href="https://dxs.app/stats"
          target="_blank"
          ><br />70% of traders lose money.</a
        >
        By using DXS, you accept the
        <a
          class="decoration--underlined"
          rel="noopener noreferrer"
          href="https://faq.dxs.app/policies/terms-and-conditions"
          target="_blank"
          >Terms and Conditions</a
        >,
        <a
          class="decoration--underlined"
          rel="noopener noreferrer"
          href="https://faq.dxs.app/policies/prohibited-persons-policy"
          target="_blank"
          >Prohibited Persons,</a
        >
        and
        <a
          href="https://faq.dxs.app/policies/risk-statement"
          class="decoration--underlined"
          rel="noopener noreferrer"
          target="_blank"
          >Risk Statement</a
        >
      </div>
      <div class="login-btns">
        <Button
          class="login-button"
          type="secondary"
          width="full"
          :fix-state="!signupLoading"
          :loading="signupLoading"
          :disabled="signupLoading"
          @click="connectFiorin({ isSignup: true })"
        >
          <Icon name="createwallet" width="36" height="36" />
          <div>
            {{ $t('popups.confirmMB.createNew') }}
          </div>
        </Button>
        <Button
          class="login-button"
          type="secondary"
          width="full"
          :loading="frLoading"
          :disabled="frLoading"
          @click="connectFiorin({})"
        >
          <Icon name="fiorin" width="36" height="36" />
          <div>
            {{ $t('popups.confirmMB.fiorin') }}
          </div>
          <!-- <div class="fee-tag" /> -->
          <!-- <div class="text">free<br />money</div> -->
        </Button>
        <Button
          class="login-button"
          type="secondary"
          width="full"
          :loading="mmLoading"
          :disabled="mmLoading"
          @click="connectFiorin({ metamaskFlag: true })"
        >
          <Icon name="metamask" width="36" height="36" />
          <div>
            {{ $t('popups.confirmMB.metamask') }}
          </div>
        </Button>
        <Button
          class="login-button"
          type="secondary"
          width="full"
          :loading="twLoading"
          :disabled="twLoading"
          @click="connectFiorin({ trustWalletFlag: true })"
        >
          <Icon name="trust-wallet" width="36" height="36" />
          <div>
            {{ $t('popups.confirmMB.trustWallet') }}
          </div>
        </Button>
        <Button
          class="login-button"
          type="secondary"
          width="full"
          :loading="hcLoading"
          :disabled="hcLoading"
          @click="connectHandcash"
        >
          <Icon name="handcash" width="36" height="36" />
          <div>{{ $t('popups.confirmMB.handCash') }}</div>
        </Button>
        <Button
          class="login-button"
          type="secondary"
          width="full"
          :loading="wcLoading"
          :disabled="wcLoading"
          @click="connectFiorin({ walletConnectFlag: true })"
        >
          <Icon name="wallet-connect" width="36" height="36" />
          <div>
            {{ $t('popups.confirmMB.walletConnect') }}
          </div>
        </Button>
        <Button
          class="login-button"
          :loading="dotWalletLoading"
          :disabled="dotWalletLoading"
          type="secondary"
          @click="connectDotwallet"
        >
          <Icon name="dotwallet" width="36" height="36" />
          <div>{{ $t('popups.confirmMB.dotWallet') }}</div>
        </Button>
      </div>
    </Card>
  </Modal>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card.vue';
import Icon from '@/components/ui/Icon.vue';
import Button from '@/components/ui/Button';
import router from '@/router';
import '@/assets/icons/moneybutton';
import '@/assets/icons/relayx';
import '@/assets/icons/dotwallet';
import '@/assets/icons/handcash';
import '@/assets/icons/fiorin';
import '@/assets/icons/trust-wallet';
import '@/assets/icons/wallet-connect';
import '@/assets/icons/createwallet';
import '@/assets/non-generated-icons/metamask';
import { useScope } from '@/compositions/useScope';
import { useModals } from '@/modules/modals/api';
import {
  // isAndroid,
  // isIOS,
  isRunningStandaloneAndroid,
} from '@/helpers/detects';
import { WalletNames } from '@/wallets/common';
import authMixin from '@/mixins/auth.mixin';

export default defineComponent({
  name: 'Login',
  mixins: [authMixin],
  setup(_, { emit }) {
    const isLoading = ref(false);
    const showDXSHello = ref(false);
    const store = useStore();
    const { isNotDesktop } = useScope();
    const mbLoading = ref(false);
    const hcLoading = ref(false);
    const frLoading = ref(false);
    const mmLoading = ref(false);
    const twLoading = ref(false);
    const wcLoading = ref(false);
    const signupLoading = ref(false);
    const showMore = ref(false);
    const dotWalletLoading = ref(false);

    const { hideModal, showModal, modalsByName } = useModals();

    const startAuth = async (provider, data) => {
      await store.dispatch(
        'auth/startLogin',
        { provider, data },
        { root: true }
      );
    };

    const hideLanding = () => {
      document.querySelector('.landing-dxs')?.classList.remove('show');
    };

    const connectFiorin = (data) => {
      hideLanding();
      if (frLoading.value || mmLoading.value) {
        return;
      }

      console.debug('#connectFiorin #loginVue', data);

      // if (activeConnect.value.provider) {
      //   // todo: [vadim] why?
      //   // localStorage.setItem('needAuthReload', true);
      //   setTimeout(() => {
      //     emit('close');
      //   }, 1000);
      // }

      const [, loadingRef] = [
        [Boolean(data.metamaskFlag), mmLoading],
        [Boolean(data.trustWalletFlag), twLoading],
        [Boolean(data.walletConnectFlag), wcLoading],
        [Boolean(data.isSignup), signupLoading],
        [true, frLoading],
      ].find(([predicate]) => predicate);

      loadingRef.value = true;

      hideModal(modalsByName.landing);

      startAuth(WalletNames.fiorin, {
        isSignup: data.isSignup,
        isMetamask: data.metamaskFlag,
        isTrustWallet: data.trustWalletFlag,
        isWalletConnect: data.walletConnectFlag,
      })
        .then(() => {
          loadingRef.value = false;
          emit('close');
        })
        .catch(() => {
          loadingRef.value = false;
          emit('close');
        });
      emit('close');
    };

    const connectDotwallet = async () => {
      if (window.navigator.standalone || isRunningStandaloneAndroid()) {
        showModal(modalsByName.standaloneAttention);
        return;
      }
      hideLanding();
      if (dotWalletLoading.value) {
        return;
      }
      dotWalletLoading.value = true;
      return startAuth(WalletNames.dotWallet);
    };

    const connectHandcash = async () => {
      // if (window.navigator.standalone || isRunningStandaloneAndroid()) {
      //   showModal(modalsByName.standaloneAttention);
      //   return;
      // }
      hideLanding();
      if (hcLoading.value) {
        return;
      }

      hcLoading.value = true;

      return startAuth(WalletNames.handCash);
    };

    const queryParams = new URLSearchParams(window.location.search);
    const flow = queryParams.get('flow');

    if (flow === 'fiorin') {
      connectFiorin();

      queryParams.delete('flow');

      const newUrl = `${window.location.origin}${
        window.location.pathname
      }/?${queryParams.toString()}`;

      router.push(newUrl);
    }

    return {
      isLoading,
      mbLoading,
      hcLoading,
      frLoading,
      twLoading,
      dotWalletLoading,
      wcLoading,
      signupLoading,
      connectDotwallet,
      connectHandcash,
      connectFiorin,
      showDXSHello,
      startAuth,
      router,
      isNotDesktop,
      mmLoading,
      wd: window,
      showMore,
    };
  },
  components: {
    Button,
    Card,
    Icon,
    Modal,
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/base';
@import '@/assets/styles/colors';

.card.login-modal {
  min-height: 545px; // 420px; // 512px; // 381px;
  box-sizing: border-box;
  transition: none;

  &.max {
    min-height: 470px; // 620px; // 381px;
  }

  .showmore {
    font-family: 'Gotham_Pro_Medium';
    font-size: 15px;
    margin: 15px auto;
    // color: #555555;
    cursor: pointer;
  }
}

.create-wallet {
  cursor: pointer;
  height: 69px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  &__title {
    color: #000;
    font-size: 18px;
    font-family: 'Gotham_Pro_Medium';
  }

  &__description {
    color: #000;
    opacity: 0.5;
    font-size: 12px;
    font-family: 'Gotham_Pro_Regular';
    margin-top: 7px;
  }
}

.login-button {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 51px;

  font-family: Gotham_Pro_Bold;
  text-transform: uppercase;

  margin: 1px auto;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 0.9375rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  .fee-tag {
    position: absolute;
    background: rgb(141, 141, 139);
    width: 150px;
    height: 140px;
    right: -65px;
    transform: rotate(35deg);
    top: 0;
  }

  .text {
    position: absolute;
    right: 12px;
    font-family: 'Gotham_Pro_Medium';
    font-size: 12px;
    text-align: right;
    color: #fff;
  }

  span {
    display: inline-block;
    font-size: 11px;
    padding-left: 5px;
    font-family: Gotham_Pro_Regular;
  }

  div {
    margin-left: 10px;
    padding-top: 2px;
  }
}

.login-title-description {
  font-family: Gotham_Pro_Regular;
  line-height: 1.25;
}

.login-btns {
  width: 100%;
  left: 0;
  top: 180px;
  display: flex;
  position: absolute;
  flex-direction: column;

  @media (max-width: 480px) {
    top: 175px;
  }
}

.login-wallet-nothave {
  font-family: 'Gotham_Pro_Medium';
  font-size: 15px;
  margin: 15px auto;
  // color: rgb(85, 85, 85);
  cursor: pointer;
}

body.twilight,
body.dark {
  .login-button {
    .fee-tag {
      background: #8d8b8b45;
    }
  }
}

body.dark {
  .login-button {
    .fee-tag {
      background: #8d8b8b2e;
    }
  }
}
</style>
