<template>
  <div class="market-tabs-new">
    <div class="row one">
      <TabButton
        max
        :active="activeIndex === 4"
        :title="$t('markets.stocks')"
        @click="selectTab(4)"
      />
      <TabButton
        max
        :active="activeIndex === 5"
        :title="$t('markets.indices')"
        class="m-10"
        @click="selectTab(5)"
      />
      <TabButton
        max
        :active="activeIndex === 6"
        :title="$t('markets.forex')"
        @click="selectTab(6)"
      />
    </div>
    <div class="row">
      <TabButton
        max
        :active="activeIndex === 2"
        :title="$t('markets.crypto')"
        class="mr-10"
        @click="selectTab(2)"
      />
      <TabButton
        max
        :active="activeIndex === 3"
        :title="$t('markets.commodities')"
        @click="selectTab(3)"
      />
    </div>
  </div>
</template>
<script>
import TabButton from '@/components/ui/TabButton.vue';

import { useRoute } from 'vue-router';
import { onMounted, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    TabButton,
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();

    const activeMarket = computed(() => store.getters['markets/activeMarket']);
    const firstRender = ref(true);

    const tabsItems = ref([
      { icon: 'starfill', width: 12, filter: '_star' },
      { icon: 'fire', width: 12, filter: '_fire' },
      { title: t('markets.crypto'), width: 36, filter: 'crypto' },
      { title: t('markets.commodities'), width: 40, filter: 'commodities' },
      { title: t('markets.stocks'), width: 34, filter: 'stocks' },
      { title: t('markets.indices'), width: 34, filter: 'indices' },
      { title: t('markets.forex'), width: 32, filter: 'forex' },
    ]);

    const selectTab = (ndx, autoTrigger = false) => {
      emit('change', {
        type: tabsItems.value[ndx].filter,
        index: ndx,
        autoTrigger,
      });
    };

    onMounted(() => {
      selectTab(props.activeIndex > -1 ? props.activeIndex : 2, true);
    });

    watch(
      () => activeMarket.value,
      () => {
        if (
          firstRender.value &&
          route.params.name &&
          activeMarket.value.group
        ) {
          const activeTabItemIndex = tabsItems.value.findIndex(
            (tab) =>
              tab.title &&
              activeMarket.value.group &&
              tab.title.toLowerCase() === activeMarket.value.group.toLowerCase()
          );

          if (activeTabItemIndex > -1) {
            selectTab(props.activeIndex);
            firstRender.value = false;
          }
        }
      }
    );

    return { selectTab };
  },
};
</script>
<style lang="scss" scoped>
.market-tabs-new {
  box-sizing: border-box;
  margin: 5px 20px;
  display: flex;
  flex-direction: column;
  height: 98px;
  transition: all ease-out 0.4s;
  opacity: 1;

  .row {
    display: flex;
    align-items: center;
    margin-left: 2px;

    &.one {
      margin-bottom: 10px;
    }
  }

  .m-10 {
    margin: 0 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }
}
</style>
