<template>
  <div class="flex flex-center no-align">
    <teleport to="body">
      <Notifications />
    </teleport>
    <div v-if="loading.isLoading || loading.isFontsLoading" />
    <router-view v-else />
    <!-- <BountyWelcome v-if="showBountyModal" @close="showBountyModal = false" /> -->
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { getUserSettings } from '@/modules/settings/api';
import { useModals } from '@/modules/modals/api';
import { useScope } from '@/compositions/useScope';
import useUserActions from '@/compositions/useUserActions';

import {
  getLocalItem,
  saveMobileLastPage,
  clearSavedPositions,
} from '@/helpers/storage';
import { MOBILE_ROUTES_WHITE_LIST } from '@/helpers/enums';

import Notifications from '@/components/app/Notifications.vue';
import notify from '@/plugins/notify';
import { useI18n } from 'vue-i18n';
// import BountyWelcome from '@/components/modals/BountyWelcome.vue';
import { initLandingListeners } from '@/helpers/initLandingListeners';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import useTheme from './compositions/useTheme';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

export default defineComponent({
  name: 'DXS-App',
  mixins: [authMixin],
  components: { Notifications },
  setup() {
    const auth = useAuth();
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const scopeComposition = useScope();
    const userActions = useUserActions();
    const { storeTheme } = useTheme();

    const showBountyModal = ref(false);

    const loading = reactive({
      isLoading: true,
      isFontsLoading: true,
    });

    const menuFavourites = computed(
      () => store.getters['settings/menuFavourites']
    );

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    const isMobile = computed(
      () =>
        scopeComposition.isSmall ||
        scopeComposition.isMedium ||
        scopeComposition.noMatch
    );

    router
      .isReady()
      .then(() => {
        // loading.isLoading = false;

        if (scopeComposition.isNotDesktop.value) {
          const lastPagePath = getLocalItem('savedMobileLastPage');

          if (
            lastPagePath &&
            lastPagePath !== route.fullPath &&
            route.fullPath === '/'
          ) {
            clearSavedPositions();
            router.push(lastPagePath);
          }
        }
      })
      .catch(() => {
        loading.isLoading = false;
      });
    document.fonts.ready.then(() => {
      loading.isFontsLoading = false;
      // event to landing
      if (document.querySelector('.landing-login.show')) {
        setTimeout(() => {
          window.document.dispatchEvent(
            new CustomEvent('callLogin', { bubbles: true })
          );
        }, 1000);
      }
    });

    document.body.classList.add(storeTheme.value);

    loading.isLoading = false;
    document.body.classList.remove('splash');
    // document.body.style.overflow = 'hidden';

    setTimeout(() => {
      // document.body.style.overflow = '';
    }, 2000);
    const { showModal, modalsByName, hideModal } = useModals();
    // handle modals stated in url
    setTimeout(() => {
      const route = router.currentRoute.value;

      if (route.query.modal) {
        let modalsFromURL = route.query.modal.split(',');
        modalsFromURL.forEach((modalName) => {
          Object.keys(modalsByName).forEach((key) => {
            if (modalsByName[key] === modalName) {
              showModal(modalName);
            }
          });
        });
      }
    }, 500);

    initLandingListeners(store, showModal, hideModal, modalsByName, router);

    const settings = computed(() => store.getters['settings/settings']);
    const kycStatus = computed(() => settings.value.kycStatus);
    const accountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    watch(
      () => ({
        status: kycStatus.value,
        userId: auth.isLoggedIn.value ? auth.userId.value : null,
      }),
      (value, oldValue) => {
        if (value.userId !== oldValue.userId) {
          return;
        }

        if (!oldValue.status || !value.status) {
          return;
        }

        if (value.status !== oldValue.status) {
          notify({
            text: t('order.new.toasts.KYCUpdated'),
            type: 'info',
          });
        }
      }
    );

    watch(
      () => auth.isLoggedIn.value,
      () => {
        if (auth.isLoggedIn.value) {
          getUserSettings()
            .then(async () => {
              setTimeout(async () => {
                console.log('ui settings', Object.assign({}, uiSettings.value));
                if (!uiSettings.value.termsAccepted) {
                  await updateAndSetUserSettings({
                    uiSettings: {
                      personsAccepted: true,
                      termsAccepted: true,
                    },
                  });

                  // if (
                  //   activeConnect.value.provider === userProviders.fiorin &&
                  //   !+accountBalance.value.available
                  // ) {
                  //   setTimeout(() => {
                  //     showModal(modalsByName.bountyTrading);
                  //   }, 8000);
                  // }

                  if (!+accountBalance.value.available) {
                    router.push('/DoYouKnowThat');
                  }

                  userActions.addLiquidityToFavourites();

                  if (auth.isFiorin.value) {
                    setTimeout(() => {
                      userActions.addTopUpWalletToFavourites();
                    }, 1000);
                  }
                }

                if (menuFavourites.value.length && auth.isFiorin.value) {
                  userActions.removeSwitchAccountFromFavourites();
                }
              }, 1000);
            })
            .catch(() => {
              notify({
                text: t('order.new.toasts.error'),
                type: 'error',
              });
            });
        }
      },
      { immediate: false }
    );

    let startPosition = 0;
    let currentPosition = 0;

    const mouseStart = (e) => {
      startPosition = e.clientY || e.targetTouches[0].pageY;
    };

    const mouseEnd = (e) => {
      currentPosition = e.clientY || e.targetTouches[0].pageY;

      if (currentPosition > 10 && currentPosition - startPosition > 250) {
        document.documentElement.scrollTop = 0;

        window.location.reload();
      }
    };

    onMounted(() => {
      if (window.navigator.standalone || isRunningStandaloneAndroid()) {
        document
          .querySelector('body')
          .addEventListener('touchstart', mouseStart, false);
        document
          .querySelector('body')
          .addEventListener('touchmove', mouseEnd, false);
      }
    });

    onUnmounted(() => {
      if (window.navigator.standalone || isRunningStandaloneAndroid()) {
        document
          .querySelector('body')
          .removeEventListener('touchstart', mouseStart);
        document
          .querySelector('body')
          .removeEventListener('touchmove', mouseEnd);
      }
    });

    return { loading, showBountyModal, isMobile };
  },
  watch: {
    $route(to) {
      if (MOBILE_ROUTES_WHITE_LIST.includes(to.name)) {
        saveMobileLastPage(to.fullPath);
      }
    },
  },
});
</script>
<style lang="scss">
@import './App.scss';
</style>
<style scoped lang="scss">
.flex {
  display: flex;
}

.flex-center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  &.no-align {
    justify-content: flex-start;
  }
}
</style>
