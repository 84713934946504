<template>
  <Button
    :text="`SEND ${marginLabel} ${fiorinMode ? 'USD-Bounty' : 'BSV'}`"
    :loading="loading"
    size="medium"
    type="secondary"
    is-html
    :fixState="true"
    class="send-btn"
    @click="$emit('onClick')"
  />
</template>

<script>
import { toCurrencyFormat } from '@/helpers/utils';
import Button from '@/components/ui/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    fiorinMode: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    marginBtnValue: {
      required: true,
    },
    margin: {
      type: String,
      default: '',
    },
  },
  computed: {
    marginLabel() {
      return toCurrencyFormat(this.marginBtnValue);
    },
  },
};
</script>

<style lang="scss">
.send-btn {
  font-family: Gotham_Pro_Medium;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
