/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'burger-toggle': {
    width: 13,
    height: 12,
    viewBox: '0 0 13 12',
    data: '<path pid="0" d="M12.645 12v-1.5h-12V12h12zm0-6.75h-12v1.547h12V5.25zm0-5.25h-12v1.547h12V0z" _fill="#0796EA"/>'
  }
})
