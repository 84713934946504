import { connApp } from '@/helpers/signalR';

// todo: [Vadim] remove if won't be needed, commented out for now
// export const getBountyBalance = async () => {
//   return await connApp.invokeWithRetry('GetBountyBalance');
// };

export const getBountyTasks = async () => {
  return await connApp.invokeWithRetry('GetBountyStatus');
};

export const getPositionStatus = async () => {
  return await connApp.invokeWithRetry('IsOpenedBountyPosition');
};

export const proposeBountyPosition = async (msg, amount) => {
  const data = {
    TransactionMessage: msg,
    TransactionAmount: amount,
  };

  const result = await connApp.invokeImmediately('ProposeBountyPosition', data);
  console.debug('#proposeBountyPosition', 'data:', data, 'result:', result);
  return result;
};
