<template>
  <div class="loader" :class="{ isBlack }">
    <div class="bounce-one bounce" />
    <div class="bounce-two bounce" />
    <div class="bounce-three bounce" />
  </div>
</template>

<script>
export default {
  props: {
    // todo: not used. remove?
    color: {
      type: String,
      default: '#fff',
    },
    isBlack: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.loader {
  margin: 0 auto;
  text-align: center;
  position: absolute;
}

.loader > div {
  width: 9px;
  height: 9px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.6s infinite ease-in-out both;
  animation: sk-bouncedelay 1.6s infinite ease-in-out both;
  margin-right: 5px;

  &:last-child {
    margin: 0;
  }
}

.loader.isBlack > div {
  background-color: #000;
}

.loader .bounce-one {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader .bounce-two {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.bounce-one,
.bounce-two,
.bounce-three {
  background-color: #fff;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.light {
  .loader > div {
    background-color: #000;
  }

  .bounce-one,
  .bounce-two,
  .bounce-three {
    background-color: #000;
  }
}
</style>
