import { createStore } from 'vuex';
import graph from './modules/graph';
import notifications from './modules/notifications';
import auth from '@/store/modules/auth/auth';
import wallet from '@/store/modules/wallet/wallet';
import fiorin from './modules/wallet/fiorin';
import handcash from '@/store/modules/wallet/handcash';
import accounts from '@/store/modules/auth/accounts';
import volumeLimits from './modules/volumeLimits';
import email from './modules/email';
import usa from './modules/usa';
import liquidity from './modules/liquidity';
import randomTrade from './modules/randomTrade';
import telegram from './modules/telegram';
import localUiSettings from './modules/localUiSettings';
import network from './modules/network';
import uiVisibility from './modules/uiVisibility';
import createListenMutationPlugin from './plugins/listenMutation';

export const cachedPaths = [
  'auth.account',
  'markets.dontShowAgain',
  'bounty.lastBalance',
  'localUiSettings.defaultProfitBtnIndex',
  'localUiSettings.defaultLossBtnIndex',
  'localUiSettings.showPositionGroup',
  'localUiSettings.marketsSearchHistory',
]
  .concat(accounts.cachedPaths || [])
  .concat(auth.cachedPaths || [])
  .concat(wallet.cachedPaths || [])
  .concat(handcash.cachedPaths || [])
  .concat(fiorin.cachedPaths || [])
  .concat(telegram.cachedPaths || []);

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    graph,
    notifications,
    auth,
    accounts,
    wallet,
    handcash,
    volumeLimits,
    email,
    usa,
    liquidity,
    fiorin,
    telegram,
    localUiSettings,
    randomTrade,
    network,
    uiVisibility,
  },
  plugins: [createListenMutationPlugin()],
});
