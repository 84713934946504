<template>
  <div
    class="form-position-confirm"
    :class="{
      hasLoyalty: +loyaltyFeeRate && loyaltyReset,
      hasEstPl: !formData.entryPrice,
      maxForm: +loyaltyFeeRate && loyaltyReset && !formData.entryPrice,
      bountyInProgram: bountyInProgram,
    }"
  >
    <div class="form-position-confirm__main-info">
      <PanelInfoLabelValue
        :title="`Margin, ${marginTokenNameFormatted}`"
        :value="marginAmountInTokenFormatted"
        :large-value="true"
        :clicked="$isFiorin && !isBounty"
        class="form-position-confirm__stats-field"
        @click="onChooseCollateral"
      />
      <PanelInfoLabelValue
        :title="`${$t('order.card.titles.amount')}, USD`"
        :value="toCurrencyFormat(amountUsd)"
        class="form-position-confirm__stats-field"
      />
      <PanelInfoLabelValue
        :title="$t('order.card.titles.entryPrice')"
        :value="`${prefixEntry} ${toCurrencyFormat(
          entryPrice,
          null,
          floatList[activeMarket.name]
        )}`"
        class="form-position-confirm__stats-field"
      />
      <PanelInfoLabelValue
        v-if="!formData.entryPrice"
        :title="$t('order.card.titles.estPl')"
        :is-red="plAmount < 0"
        :is-green="plAmount > 0"
        :value="plValue"
        class="form-position-confirm__stats-field"
      />
    </div>

    <div
      v-if="
        +loyaltyFeeRate &&
        loyaltyReset &&
        !settings.doNotShowLiquidityFeeWarning
      "
      v-html="$t('order.card.titles.liqFeeHint')"
      class="loyalty-hint"
    />
    <ItemLabelValue
      :label="$t('order.card.titles.leverage')"
      :hint-options="{ top: '-55px', left: '80px' }"
      :hint-title="'Example comment: it allows<br>you to multiply your buying<br>power. Example: to open a Ƀ<br>100 position at 100x leverage,<br>you need just Ƀ 1 in actual<br>balance.'"
      :value="formData.leverage + 'x'"
      width-hint="190px"
      class="form-position-confirm__stats-field mt24"
    />
    <ItemLabelValue
      :label="$t('order.card.titles.liquidationPrice')"
      :value="`${prefixLiquidation} ${toCurrencyFormat(
        liquidationPrice,
        null,
        floatList[activeMarket.name]
      )}`"
      class="form-position-confirm__stats-field mt24"
    />
    <ItemLabelValue
      v-if="+loyaltyFeeRate && loyaltyReset"
      :label="$t('blocks.titles.loyaltyFee')"
      :hint-options="{ top: '-55px', left: '100px' }"
      :hint-title="$t('blocks.titles.loyaltyFeeHint')"
      :value="`${loyaltyFeeRate.toFixed(2)}%`"
      class="form-position-confirm__stats-field mt24"
    />
    <div v-if="bountyInProgram" class="loyalty-hint">
      IMPORTANT: Trading positions opened with USD-Bounty will not earn activity
      points toward completing your ongoing challenge
    </div>
    <div
      v-if="config && config.margin_pool_account"
      :class="{ standalone: wd.navigator.standalone || isTelegram }"
      class="payment-container"
    >
      <FormPositionConfirmPayment
        :type="orderType"
        :is-bounty="isBounty"
        :to="config.margin_pool_account"
        :amount-usd="amountUsd"
        :entry-price="formData.entryPrice"
        :margin-in-token-to-pay="marginInToken"
        :margin-in-token-to-display="marginAmountInTokenFormatted"
        :margin-token-name="marginTokenNameFormatted"
        :stop-loss="formData.stopLossPercent"
        :take-profit="formData.takeProfitPercent"
        @payment="emit('payment')"
        @error="emit('error')"
        @close="emit('onCloseOrderConfirm')"
      />
    </div>
  </div>
</template>
<script>
import FormPositionConfirmPayment from './FormPositionConfirmPayment.vue';
import {
  toCurrencyFormat,
  usdFormatWithPercent,
  roundTo12Decimals,
  roundUsdNumber,
  getPrecisionByPrice,
} from '@/helpers/utils';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { PositionSides } from '@/config/constants';
import bsvPrice from '@/compositions/bsvPrice';
import { floatList } from '@/helpers/floatList';
import useCalcPl from '@/compositions/useCalcPl';
import { useMarketPriceForMargin } from '@/compositions/useMarketPriceForMargin';
import { useBountyPrograms } from '@/compositions/useBountyPrograms';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';
import PanelInfoLabelValue from '@/components/ui/PanelInfoLabelValue';
import { useModals } from '@/modules/modals/api';
import ItemLabelValue from '@/components/ui/ItemLabelValue';
import debounce from 'lodash/debounce';

export default {
  name: 'FormPositionConfirm',
  mixins: [authMixin],
  components: {
    FormPositionConfirmPayment,
    ItemLabelValue,
    PanelInfoLabelValue,
  },
  props: {
    orderType: { required: true },
    formData: { required: true },
    isBounty: Boolean,
    activeMarket: { required: true, type: Object },
  },
  emits: ['payment', 'error', 'onCloseOrderConfirm'],
  setup(props, { emit }) {
    const { showModal, modalsByName } = useModals();
    const auth = useAuth();
    const loyaltyReset = ref(true);
    const store = useStore();

    const activeMarket = computed(() => props.activeMarket);

    const { pl, pl_percent } = useCalcPl(
      props.orderType.toLowerCase(),
      activeMarket,
      props.formData.amountUsd,
      props.formData.entryPrice,
      props.formData.leverage
    );

    const tokensList = computed(() => {
      if (!auth.isFiorin.value) {
        return [];
      }

      return store.getters['fiorin/activeTokens'];
    });

    const activeTokenName = computed(() => {
      if (auth.isFiorin.value) {
        return (
          store.getters['tokenSelector/collateralToken/name'] ||
          tokensList.value[0]?.name
        );
      }

      return 'BSV';
    });

    const activeTokenBalanceInToken = computed(() => {
      if (auth.isFiorin.value) {
        return (
          store.getters['tokenSelector/collateralToken/amount'] ||
          tokensList.value[0]?.amount
        );
      }

      return store.getters['handcash/amountBsv'];
    });

    console.debug(
      '#formPositionConfirm activeTokenName ',
      activeTokenName.value
    );

    const accountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    const activeTokenCurrency = computed(() => {
      if (auth.isFiorin.value) {
        return store.getters['tokenSelector/collateralToken/currency'];
      }

      return 'BSV';
    });

    const { marketPriceFromFiorinCurrency } =
      useMarketPriceForMargin(activeTokenCurrency);

    const activeTokenPrice = computed(() => {
      if (auth.isFiorin.value) {
        return marketPriceFromFiorinCurrency.value;
      }

      return bsvPrice();
    });

    const prefixEntry = computed(() =>
      props.orderType.toLowerCase() === PositionSides.BUY ? 'ASK' : 'BID'
    );
    const prefixLiquidation = computed(() =>
      props.orderType.toLowerCase() === PositionSides.BUY ? 'BID' : 'ASK'
    );

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    const plAmount = computed(() => pl.value);

    const plValue = computed(() => {
      return usdFormatWithPercent(pl.value, pl_percent.value);
    });

    const markets = computed(() => store.getters['markets/markets']);

    const loyaltyFeeInfo = computed(
      () => store.getters['volumeLimits/loyaltyFeeInfo']
    );

    const loyaltyFeeRate = computed(
      () => store.getters['volumeLimits/loyaltyFeeRate']
    );

    const entryPrice = computed(() => {
      if (props.formData.entryPrice) {
        return props.formData.entryPrice;
      }

      const current = markets.value.find(
        (item) =>
          `${item.ticker}/${item.base}` ===
          `${activeMarket.value.ticker}/${activeMarket.value.base}`
      );
      return props.orderType === PositionSides.SELL
        ? current.sell
        : current.buy;
    });

    const maxBalanceTokenAmountUsd = computed(() => {
      if (auth.isFiorin.value) {
        return store.getters['fiorin/maxBalanceToken/amountUsd'];
      } else {
        return store.getters['handcash/amountUsd'];
      }
    });

    const liquidationPrice = computed(() => {
      const leverage = props.formData.leverage;

      const entryPrice =
        props.formData.entryPrice || activeMarket.value.usdPrice;

      const LIQUIDATION_FACTOR = 0.8;
      let factor = LIQUIDATION_FACTOR / leverage;
      if (props.orderType.toLowerCase() === PositionSides.BUY) {
        factor = -factor;
      }

      const result = entryPrice * (1 + factor);
      return result;
    });

    const amountUsd = computed(() => {
      return roundUsdNumber(Math.max(props.formData.amountUsd || 0, 0));
    });

    // not greater than token with max balance
    const marginUsdSafe = computed(() => {
      let margin = +amountUsd.value / props.formData.leverage;
      if (!props.isBounty) {
        margin = Math.min(margin, maxBalanceTokenAmountUsd.value);
      }
      margin = roundTo12Decimals(margin);
      return margin;
    });

    if (auth.isFiorin.value) {
      store.commit('tokenSelector/setMarginAmountUsd', marginUsdSafe.value);
    }

    const debouncedUpdate = debounce((value) => {
      store.commit('tokenSelector/setMarginAmountUsd', value);
    }, 50);

    onUnmounted(() => {
      debouncedUpdate.cancel();
    });

    watch(() => marginUsdSafe.value, debouncedUpdate);

    const isBountyPos = computed(() => {
      return (
        props.isBounty && accountBalance.value.bounty >= marginUsdSafe.value
      );
    });

    const marginInToken = computed(() => {
      if (isBountyPos.value) {
        return marginUsdSafe.value;
      }

      let result = marginUsdSafe.value / activeTokenPrice.value;

      if (result > activeTokenBalanceInToken.value) {
        console.debugError(
          'Not error, but why is that happening? (value will be replaced and go on)',
          '#formPositionConfirm marginInToken is more than balance',
          'activeTokenBalanceInToken:',
          activeTokenBalanceInToken.value,
          '\n',
          'marginInToken:',
          result
        );

        result = activeTokenBalanceInToken.value;
      }

      result = roundTo12Decimals(result);
      return result;
    });

    const marginAmountInTokenFormatted = computed(() => {
      if (
        !props.formData ||
        !props.formData.amountUsd ||
        !props.formData.leverage ||
        !+props.formData.leverage
      ) {
        return '';
      }

      if (isBountyPos.value) {
        return toCurrencyFormat(marginInToken.value, null, 2);
      }

      const tokenName = activeTokenName.value.toLowerCase();
      const precision =
        tokenName.includes('usdt') ||
        tokenName.includes('usdc') ||
        tokenName.includes('dai')
          ? 2
          : getPrecisionByPrice(marginInToken.value);

      return toCurrencyFormat(marginInToken.value, null, precision);
    });

    const marginTokenNameFormatted = computed(() => {
      const openRandomPositionFlag = computed(
        () => store.getters['randomTrade/openRandomPositionFlag']
      );

      if (auth.isFiorinMode.value) {
        const bountyPos = openRandomPositionFlag.value || isBountyPos.value;
        return `${bountyPos ? 'USD-Bounty' : activeTokenName.value}`;
      }

      return 'BSV';
    });

    const config = computed(() => store.getters['user/wsConfig']);

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const onChooseCollateral = () => {
      if (auth.isFiorinMode.value && !props.isBounty) {
        showModal(modalsByName.chooseCollateral);
      }
    };

    const { referralProgram } = useBountyPrograms();
    const bountyInProgram = computed(() => {
      return isBountyPos.value && referralProgram;
    });

    onMounted(() => {
      // loyaltyReset.value = true;
      console.log('tokensList', tokensList.value);
      if (auth.isFiorin.value) {
        if (!isBountyPos.value) {
          store.dispatch(
            'tokenSelector/collateralToken/setTokenId',
            tokensList.value[0]?.tokenId
          );
        }

        store.dispatch(
          'volumeLimits/fetchLoyaltyFeeInfo',
          activeMarket.value.id
        );
      }
      // loyaltyReset.value = true;
    });

    return {
      // isNotStable,
      wd: window,
      isTelegram,
      bountyInProgram,
      settings,
      liquidationPrice,
      toCurrencyFormat,
      entryPrice,
      amountUsd,
      marginInToken,
      marginAmountInTokenFormatted,

      marginTokenNameFormatted,
      config,
      emit,
      loyaltyFeeInfo,
      loyaltyFeeRate,
      accountBalance,
      floatList,
      pl,
      pl_percent,
      plValue,
      plAmount,
      loyaltyReset,
      prefixEntry,
      prefixLiquidation,
      onChooseCollateral,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-position-confirm {
  display: flex;
  flex-direction: column;
  // min-height: calc(100vh - 250px);
  min-height: 492px;
  max-height: 492px;

  &__main-info {
    border-radius: 6px;
    overflow: hidden;
    width: calc(100% + 15px);
    margin-left: -6px;
  }

  &__stats-total {
    width: 100%;
    margin-left: -20px;
    margin-bottom: 25px;
    width: calc(100% + 40px);
    padding: 15px 20px;

    @media screen and (min-width: 1024px) {
      margin-left: -40px;
      width: calc(100% + 80px);
      padding: 15px 40px;
    }
  }

  .payment-container {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 50px;
    z-index: 1;

    &.standalone {
      bottom: 82px;
    }

    @media screen and (min-width: 1024px) {
      bottom: 0;
    }
  }

  &__stats-field {
    margin-bottom: 1px;

    &:last-child {
      margin-bottom: 0;
    }

    &.mt24 {
      margin-top: 24px;
    }

    &.mb-24 {
      margin-bottom: 24px;
    }
  }

  .loyalty-hint {
    margin-top: 24px;
    font-family: 'Gotham_Pro_Regular';
  }

  &__description {
    margin: 15px 0;
    font-family: Gotham_Pro_Medium;
  }
}
</style>
