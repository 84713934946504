import Connector from './Connector';
import { waitUntil } from '@/helpers/retry';
import { setupIframeConnection } from '@/wallets/fiorin/iframeConnection';
import { createIframeController } from '@/wallets/fiorin/iframeController';
import { v4 as uuidv4 } from 'uuid';

const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

const WalletEvents = [
  'READY',
  'ERROR',
  'REFILL_COMPLETED',
  'WITHDRAWAL',
  'DEPOSIT',
  'BALANCES',
  'PENDING_BALANCE',
  'BOUNTY_BALANCE',
  'AUTHORIZED',
  'AUTHORIZED_COMPLETED',
  'LOGOUT_COMPLETED',
  'CLOSED',
  'FIORIN_DELAYED',
  'OPEN_ME',
];

const SystemEvents = [
  'READY',
  'AUTH_REQUIRED',
  'LOGIN_REQUIRED',
  'OPEN_LINK',
  'CLOSED',
  'DECLINED',
  'BALANCES_REFRESHED',
  'SUBSCRIBE_BALANCE_CHANGES',
  'UNKNOWN',
];

const AllEvents = [...WalletEvents, ...SystemEvents];

export default class FiorinConnector extends Connector {
  constructor(eventHandlers, opts) {
    console.debug('#FiorinConnector #constructor');
    super(opts);

    this.accountId = opts.id || uuidv4();
    this.provider = 'Fiorin';
    this.eventHandlers = eventHandlers;
    this.balancesLog = [];
    //this.prefix = 'fiorin_' + Math.floor(10000 * Math.random());
    this.appId = opts.appId;

    this.accessToken = opts.accessToken;
    this._frameConnection = null;
    this._frameController = null;
  }

  async viewDeposit() {
    await this._frameConnection.sendMessageAsync('VIEW:DEPOSIT', null);
  }

  async viewWallet() {
    await this._frameConnection.sendMessageAsync('VIEW:WALLET', null);
  }

  async refill(payload) {
    //todo: [vadim] validate payload

    console.debug('#FiorinConnector #refill', payload);

    if (!payload.type) {
      throw new Error('Refill type is required');
    }

    if (!payload.amount) {
      throw new Error('Refill amount is required');
    }

    if (!payload.message) {
      throw new Error('Refill message is required');
    }

    const response = await this._frameConnection.sendMessageAsync(
      'REFILL',
      payload
    );

    return response?.payload;
  }

  async logout() {
    console.debug('#FiorinConnector #logout');
    await this._frameConnection.sendMessageAsync('LOGOUT', null);
  }

  disconnect() {
    console.debug('#FiorinConnector #disconnect');
    try {
      this._frameConnection?.disconnect();
      this._frameController?.removeFiorinFrame();

      this._frameConnection = null;
      this._frameController = null;
    } catch (e) {
      console.error('Error on disconnect #FiorinConnector', e);
    }
  }

  async connect({
    isMetamask,
    isTrustWallet,
    isWalletConnect,
    isSignup,
    allowDialogWhenUnauthorized,
  } = {}) {
    console.debug(
      `#connect #FiorinConnector 
      isMetamask: ${isMetamask} 
      isTrustWallet: ${isTrustWallet}
      isWalletConnect: ${isWalletConnect} 
      isSignup: ${isSignup},
      allowDialog: ${allowDialogWhenUnauthorized},
      host: ${FIORIN_HOST}`
    );

    if (typeof allowDialogWhenUnauthorized !== 'boolean') {
      console.error(
        'allowDialog is not set, defaulting to true #FiorinConnector #connect',
        '\n',
        'allowDialog:',
        allowDialogWhenUnauthorized
      );
      allowDialogWhenUnauthorized = true;
    }

    const eventHandlers = this.eventHandlers;
    validateEventHandlers(eventHandlers);

    const [, hostUrl] = [
      [isMetamask, `${FIORIN_HOST}/web3?wallet=metamask`],
      [isTrustWallet, `${FIORIN_HOST}/web3?wallet=trust`],
      [isWalletConnect, `${FIORIN_HOST}/web3`],
      [isSignup, `${FIORIN_HOST}/sign-up`],
      [true, FIORIN_HOST],
    ].find(([predicate]) => predicate);

    const accountId = this.accountId;
    const frameId = 'FiorinSingleFrame';
    const frameController = createIframeController(frameId, hostUrl, () => {
      eventHandlers.FIORIN_DELAYED();
    });

    let anyMessageReceived = false;
    let authenticatedUserName = null;
    let balancesShownOnce = false;

    const frameConnection = setupIframeConnection(
      frameController.frame,
      FIORIN_HOST,
      '#FiorinConnector',
      () => {
        eventHandlers.FIORIN_DELAYED();
      },
      async (event) => {
        // [Vadim] in this handler, do never destroy the frame, or connection to it.
        // They should be destroyed only from external code by: 'connector.disconnect()'

        const eventName = event.data.event;
        const payload = event.data.payload;

        if (eventName !== 'BALANCES' || !balancesShownOnce) {
          console.debug(
            '#EVENT #onMessageHandler #FiorinConnector',
            'data.event:',
            event.data.event,
            'event.data:',
            event.data,
            'event:',
            event
          );

          if (eventName === 'BALANCES') {
            balancesShownOnce = true;
          }
        }

        anyMessageReceived = true;

        if (!AllEvents.includes(eventName)) {
          console.error(
            `Unknown event: '${eventName}' #FiorinConnector #onMessageHandler`
          );
          return;
        }

        let sendMessageToFiorinAsync = frameConnection.sendMessageAsync;

        if (eventName === 'READY') {
          await eventHandlers.READY(accountId);
          return;
        }

        if (eventName === 'OPEN_ME') {
          await eventHandlers.OPEN_ME(accountId);
          frameController.showFrame('OPEN_ME');
          return;
        }

        if (eventName === 'LOGIN_REQUIRED') {
          if (!this.appId) {
            this.notifyHasAuthResult(false);

            if (allowDialogWhenUnauthorized) {
              frameController.showFrame('LOGIN_REQUIRED');
            }

            return;
          }

          const p = this.appId;
          // clear to avoid recursion! Before sending the message!
          this.appId = null;
          await sendAppId(p, sendMessageToFiorinAsync);

          return;
        }

        if (eventName === 'OPEN_LINK') {
          window.open(payload.url, payload.target, payload.features);
          return;
        }

        if (eventName === 'AUTHORIZED') {
          const payloadUserName = payload.user.username;

          // fiorin sends this event after sleep.
          // we check if user is already authenticated
          // and check if it's the same user
          if (authenticatedUserName) {
            if (payloadUserName === authenticatedUserName) {
              console.debug(
                'Same user is already authenticated, ignoring #AUTHORIZED event #FiorinConnector #onMessageHandler'
              );
              return;
            }

            console.error(
              'Different user is already authenticated, logging out, #AUTHORIZED #FiorinConnector #onMessageHandler'
            );
            await sendMessageToFiorinAsync('LOGOUT', null);
          }

          authenticatedUserName = payloadUserName;

          frameController.closeFrame();

          await eventHandlers.AUTHORIZED(accountId, payload);
          // this place was for the case when we had to do something after AUTHORIZED but before AUTHORIZED_COMPLETED
          await eventHandlers.AUTHORIZED_COMPLETED(accountId, {
            payload,
            connector: this,
          });

          // should be called only after eventHandlers.AUTHORIZED
          this.notifyHasAuthResult(true);

          await sendMessageToFiorinAsync('SUBSCRIBE_BALANCE_CHANGES', null);
          return;
        }

        if (eventName === 'LOGOUT_COMPLETED') {
          if (authenticatedUserName) {
            await eventHandlers.LOGOUT_COMPLETED(accountId);
            this.notifyHasAuthResult(false);
            return true;
          }

          this.notifyHasAuthResult(false);
          return false;
        }

        if (eventName === 'CLOSED' || eventName === 'DECLINED') {
          frameController.closeFrame();
          if (!authenticatedUserName) {
            // [Vadim] probably don't need it here, if we decide to use single iframe instance
            // but if use many frames, need to re-think
            // frameConnection.disconnect();
            // frameController.removeFiorinFrame();
          }
          await eventHandlers.CLOSED(accountId);
          this.notifyHasAuthResult(false);
          return;
        }

        if (eventName === 'ERROR') {
          await eventHandlers.ERROR(accountId, payload);
          return;
        }

        if (
          eventName === 'SUBSCRIBE_BALANCE_CHANGES' ||
          eventName === 'BALANCES_REFRESHED'
        ) {
          // nothing to do here
          return;
        }

        if (WalletEvents.includes(eventName)) {
          await eventHandlers[eventName](accountId, payload);
          return;
        } else {
          console.error(
            `Unhandled event: '${eventName}' #FiorinConnector #onMessageHandler`
          );
        }

        if (eventName === 'UNKNOWN') {
          // nothing to do here
        }
      }
    );

    let fiorinStartedWorking = await waitUntil(
      '#fiorin #connector #connect',
      60_000,
      100,
      () => anyMessageReceived
    );

    if (!fiorinStartedWorking) {
      console.error(
        'Fiorin did not start working in 60 seconds #FiorinConnector #connect'
      );
    }

    this._frameConnection = frameConnection;
    this._frameController = frameController;
    console.debug(
      'exit #connect #FiorinConnector, frame:',
      frameController.frame
    );
    return this;
  }
}

async function sendAppId(appId, sendMessageToFiorinAsync) {
  if (appId && appId.trim().length > 0) {
    console.debug(
      'Sending appId to #FiorinConnector packet, appId type:',
      typeof appId
    );

    await sendMessageToFiorinAsync('APP_ID', {
      packet: appId,
    });
  }
}

function validateEventHandlers(eventHandlers) {
  {
    if (!eventHandlers) {
      throw new Error(
        'eventHandlers is required parameter #FiorinConnector #connect'
      );
    }

    if (typeof eventHandlers !== 'object') {
      throw new Error(
        'eventHandlers should be an object #FiorinConnector #connect'
      );
    }

    const missingHandlers = [];
    for (let handler of WalletEvents) {
      if (typeof eventHandlers[handler] !== 'function') {
        missingHandlers.push(handler);
      }
    }

    if (missingHandlers.length) {
      throw new Error(
        `Missing handlers: ${missingHandlers.join(
          ', '
        )} #FiorinConnector #connect`
      );
    }

    const unknownHandlers = [];
    for (let handler in eventHandlers) {
      if (!AllEvents.includes(handler)) {
        unknownHandlers.push(handler);
      }
    }

    if (unknownHandlers.length) {
      throw new Error(
        `Unknown handlers: ${unknownHandlers.join(
          ', '
        )} #FiorinConnector #connect`
      );
    }
  }
}
