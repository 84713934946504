import throttle from 'lodash/throttle';

//[Vadim] do not use 'onpointerdown' event. It brings a lot of problems with it.
const events = ['click'];

export default {
  beforeMount(el, binding) {
    let handle = (event) => {
      // [Vadim] some events come from the elements that are already deleted from the document
      if (!document.contains(event.target)) {
        return;
      }

      const ignoreChild = el.querySelector('.ignoreEventTracking');

      const contains = ignoreChild.contains(event.target);
      // If the event happened inside the target child, do nothing
      if (ignoreChild && contains) {
        return;
      }

      // console.nativeDebug(
      //   'click-outside-target #clickOutsideTarget #directives',
      //   '\n',
      //   'el:',
      //   el,
      //   '\n',
      //   'event:',
      //   event,
      //   '\n',
      //   // 'ignoreChild:',
      //   // ignoreChild,
      //   // '\n',
      //   'contains:',
      //   contains
      // );

      binding.value(event);
    };

    handle = throttle(handle, 500);

    el._handleOutsideTargetClick = (event) => {
      setTimeout(() => handle(event), 10);
    };

    const iframes = el.querySelectorAll('iframe');

    events.forEach((event) => {
      el.addEventListener(event, el._handleOutsideTargetClick, true);

      iframes.forEach((iframe) => {
        try {
          iframe.contentWindow.addEventListener(
            event,
            el._handleOutsideTargetClick,
            true
          );
        } catch (e) {
          console.warn(
            'Cannot add event listener to iframe due to cross-origin restrictions',
            e
          );
        }
      });
    });
  },

  unmounted(el) {
    const iframes = el.querySelectorAll('iframe');

    events.forEach((event) => {
      el.removeEventListener(event, el._handleOutsideTargetClick, true);

      iframes.forEach((iframe) => {
        try {
          iframe.contentWindow.removeEventListener(
            event,
            el._handleOutsideTargetClick,
            true
          );
        } catch {
          // Ignore
        }
      });
    });

    el._handleOutsideTargetClick.cancel();
  },
};
