import { PositionSides } from '@/config/constants';

export const formPositionStore = {
  namespaced: true,

  state: {
    isExpanded: true,
    isFormShown: false,

    marketId: null,
  },

  getters: {
    isExpanded(state) {
      return state.isExpanded;
    },

    isFormShown(state) {
      return state.isFormShown;
    },

    activeMarket(state, getters, rootState, rootGetters) {
      const result = rootGetters['markets/activeMarket'] || {};
      return result;
    },

    activeMarketId(state, getters, rootState, rootGetters) {
      return rootGetters['markets/activeMarketId'];
    },

    maxLeverage(state, getters) {
      return getters.activeMarket.max_leverage;
    },

    positionType(state, getters) {
      return state.isFormShown
        ? getters._current.positionType || PositionSides.BUY
        : null;
    },

    amountUsd(state, getters) {
      return getters._current.amountUsd;
    },

    amountPercent(state, getters) {
      return getters._current.amountPercent;
    },

    leverage(state, getters) {
      let result = getters._current.leverage || getters.maxLeverage;

      result = Math.min(result, getters.maxLeverage);
      result = Math.max(result, 1);

      return result;
    },

    entryPrice(state, getters) {
      if (state.isExpanded) {
        return getters._current.entryPrice;
      }
    },

    takeProfit(state, getters) {
      if (state.isExpanded) {
        return getters._current.takeProfit;
      }
    },

    stopLoss(state, getters) {
      if (state.isExpanded) {
        return getters._current.stopLoss;
      }
    },

    hasError(state, getters) {
      // [Vadim] use exactly getters! to check isExpanded
      if (getters.isExpanded) {
        return getters._current.hasError;
      }
    },

    all(state, getters) {
      return getters._current;
    },

    _byMarket(state, getters, rootState, rootGetters) {
      return rootGetters['settings/uiSettings']['formPositionMarkets'] || {};
    },

    _current(state, getters) {
      const m = getters._byMarket[getters.activeMarketId] || {};
      return {
        positionType: m.positionType,
        amountUsd: m.amountUsd,
        amountPercent: m.amountPercent,
        leverage: m.leverage,
        entryPrice: m.entryPrice,
        stopLoss: m.stopLoss,
        takeProfit: m.takeProfit,
        hasError: m.hasError,
      };
    },
  },

  mutations: {
    setIsExpanded(state, value) {
      state.isExpanded = value;
    },

    setIsFormShown(state, value) {
      state.isFormShown = value;
    },
  },

  // we need to use actions but not in mutations - in order to be able to use activeMarketId, maxLeverage from getters
  actions: {
    async setMarketForm({ commit, getters }, data) {
      commit(
        'settings/SET_UISETTING',
        {
          delayed: true,

          k: 'formPositionMarkets',
          v: {
            ...getters._byMarket,
            [getters.activeMarketId]: {
              ...getters._current,
              ...data,
            },
          },
        },
        { root: true }
      );
    },

    async setLeverage({ dispatch, getters }, leverage) {
      leverage = +leverage;

      if (!leverage) {
        console.debug(
          '#setLeverage #formPosition #store Bad value:',
          'leverage:',
          leverage
        );
        return;
      }

      let newLeverage = Math.min(leverage, getters.maxLeverage);
      newLeverage = Math.max(newLeverage, 1);

      console.debug(
        '#setLeverage #formPosition #store',
        'leverage:',
        leverage,
        'newLeverage:',
        newLeverage
      );

      await dispatch('setMarketForm', {
        leverage: newLeverage,
      });
    },

    async setEntryPrice({ dispatch }, entryPrice) {
      if (entryPrice) {
        entryPrice = +entryPrice;
      }

      await dispatch('setMarketForm', {
        entryPrice,
      });
    },

    async setAmountPercent({ dispatch }, amountPercent) {
      amountPercent = +amountPercent;
      console.debug(
        '#setAmountPercent #formPosition #store',
        'amountPercent:',
        amountPercent
      );

      await dispatch('setMarketForm', {
        amountPercent: amountPercent,
      });
    },

    async setAmountUsd({ dispatch }, { amountUsd, from, clearAmountPercent }) {
      if (amountUsd) {
        amountUsd = +amountUsd;
      }

      console.debug(
        '#setAmountUsd #formPosition #store',
        '\n',
        'amountUsd:',
        amountUsd,
        '\n',
        'from:',
        from
      );

      if (amountUsd < 0) {
        console.error(
          'Why pass negative amountUsd? #trySetAmountUsd #formPosition #store',
          '\n',
          'amountUsd:',
          amountUsd
        );
        return;
      }

      const value = {
        amountUsd,
      };

      if (clearAmountPercent) {
        value.amountPercent = null;
      }

      await dispatch('setMarketForm', value);
    },

    async setStopLoss({ dispatch }, stopLoss) {
      if (stopLoss) {
        stopLoss = +stopLoss;
      }

      console.debug('#setStopLoss #formPosition #store', 'stopLoss:', stopLoss);

      await dispatch('setMarketForm', {
        stopLoss,
      });
    },

    async setTakeProfit({ dispatch }, takeProfit) {
      if (takeProfit) {
        takeProfit = +takeProfit;
      }

      console.debug(
        '#setTakeProfit #formPosition #store',
        'takeProfit:',
        takeProfit
      );

      await dispatch('setMarketForm', {
        takeProfit,
      });
    },

    async setHasError({ dispatch }, hasError) {
      console.debug('#setHasError #formPosition #store', 'hasError:', hasError);

      await dispatch('setMarketForm', {
        hasError,
      });
    },

    async setPositionType({ dispatch }, positionType) {
      console.debug(
        '#setPositionType #formPosition #store',
        'positionType:',
        positionType
      );

      positionType = positionType?.toLowerCase() ?? PositionSides.BUY;

      if (![PositionSides.BUY, PositionSides.SELL].includes(positionType)) {
        console.error(
          'Bad type! #setOrderType #formPosition #store',
          'Bad type:',
          positionType
        );
        positionType = PositionSides.BUY;
      }

      await dispatch('setMarketForm', {
        positionType,
      });
    },

    async resetAllButLeverageAndType({ dispatch }) {
      console.debug('#resetAllButLeverage #formPosition #store');

      await dispatch('setMarketForm', {
        amountUsd: null,
        amountPercent: null,
        entryPrice: null,
        stopLoss: null,
        takeProfit: null,
      });
    },
  },
};

export const cachedPaths = [
  'formPosition.isFormShown',
  'formPosition.isExpanded',
];
