<template>
  <div>
    <TableRow
      v-for="(position, ndx) in visiblePositions"
      :key="ndx"
      :dataset="dataset"
      :class="{
        pending: position.type === 'pending',
        inSleep: sleepLoading,
        showLastPositionSell:
          ndx === 0 && showLastPosition && position.side === PositionSides.SELL,
        showLastPositionBuy:
          ndx === 0 && showLastPosition && position.side === PositionSides.BUY,
      }"
      class="positions-list-item"
      @click="setCurrentPosition(position)"
      :style="{
        display: grid ? 'grid' : '',
        'grid-template-columns': grid,
      }"
    >
      <template v-slot:0>
        <!-- BUY -->
        <Icon
          v-if="
            position.side === PositionSides.BUY &&
            !position.take_profit_price &&
            !position.stop_loss_price
          "
          name="buy"
          width="15"
          height="16"
          color="#509F96"
          class="side-icon mini2"
        />
        <Icon
          v-if="
            position.side === PositionSides.BUY &&
            position.take_profit_price &&
            !position.stop_loss_price
          "
          name="buy_simple"
          width="13"
          height="14"
          color="#509F96"
          class="side-icon mini"
        />
        <div
          v-if="
            position.side === PositionSides.BUY &&
            position.take_profit_price &&
            position.stop_loss_price
          "
          class="icon-tp buy"
        >
          <Icon name="buy_simple" width="11" height="11" color="#509F96" />
        </div>
        <Icon
          v-if="
            position.side === PositionSides.BUY &&
            !position.take_profit_price &&
            position.stop_loss_price
          "
          name="buy"
          width="15"
          height="16"
          color="#509F96"
          :class="{ greenStopLossBorder: position.stop_loss_price }"
          class="side-icon"
        />
        <!-- SELL -->
        <Icon
          v-if="
            position.side === PositionSides.SELL &&
            !position.take_profit_price &&
            !position.stop_loss_price
          "
          name="sell"
          width="15"
          height="16"
          color="#DE5E56"
          class="side-icon mini2"
        />
        <Icon
          v-if="
            position.side === PositionSides.SELL &&
            position.take_profit_price &&
            !position.stop_loss_price
          "
          name="sell_simple"
          width="13"
          height="14"
          color="#DE5E56"
          class="side-icon mini"
        />
        <div
          v-if="
            position.side === PositionSides.SELL &&
            position.take_profit_price &&
            position.stop_loss_price
          "
          class="icon-tp"
        >
          <Icon name="sell_simple" width="11" height="11" color="#DE5E56" />
        </div>
        <Icon
          v-if="
            position.side === PositionSides.SELL &&
            !position.take_profit_price &&
            position.stop_loss_price
          "
          name="sell"
          width="15"
          height="16"
          color="#DE5E56"
          :class="{ redStopLossBorder: position.stop_loss_price }"
          class="side-icon"
        />
      </template>
      <template v-slot:1>
        <div
          @click.stop="openChart(position)"
          class="item market"
          v-html="marketTicker(position.market)"
        />
      </template>
      <template v-slot:2>
        <div class="item amount currency">{{ calcAmount(position) }}</div>
      </template>
      <template v-slot:3>
        <div v-if="!isMobile">
          <!-- HISTORY -->
          <div v-if="isHistory" class="item entry currency">
            {{ calcEntryPrice(position) }}
          </div>
          <!-- PENDING/OPEN -->
          <div v-else class="item-double">
            <div class="date currency">{{ calcEntryPrice(position) }}</div>
            <div
              :class="{
                increase: hasClosedIncreased(position.id),
                decrease: hasClosedDecreased(position.id),
              }"
              class="gray currency"
            >
              {{ calcClosePrice(position) }}
            </div>
          </div>
        </div>
        <div v-else>
          <!-- MOBILE -->
          <!-- OPEN/HISTORY -->
          <div
            v-if="(position.type !== 'pending' && isOpen) || isHistory"
            :class="{
              increase: hasPlIncreased(position.id),
              decrease: hasPlDecreased(position.id),
              'item-double down': position.state !== 'canceled',
            }"
          >
            <template v-if="position.type === 'open'">
              <div
                v-if="position.state !== 'canceled'"
                :class="{
                  down: calcPL(position, true)[0] === '-',
                }"
                class="mt-4 up currency"
              >
                {{ calcPL(position, true) }}
              </div>
              <div
                v-if="position.state !== 'canceled'"
                :class="{
                  down: calcPlPercent(position, true)[0] === '-',
                }"
                class="centarell-bold up"
              >
                {{ calcPlPercent(position, true) }}%
              </div>
            </template>
            <template v-else>
              <!-- HISTORY PL % -->
              <div
                v-if="position.state !== 'canceled'"
                :class="{
                  down:
                    calcPL(position, true, 'netBalance', 'netBalancePct')[0] ===
                    '-',
                }"
                class="mt-4 up currency"
              >
                {{ calcPL(position, true, 'netBalance', 'netBalancePct') }}
              </div>
              <div
                v-if="position.state !== 'canceled'"
                :class="{
                  down:
                    calcPlPercent(position, true, 'netBalancePct')[0] === '-',
                }"
                class="centarell-bold up"
              >
                {{ calcPlPercent(position, true, 'netBalancePct') }}%
              </div>
            </template>
            <div v-if="isHistory && position.state === 'canceled'" class="item">
              {{ $t('order.history.canceled') }}
            </div>
          </div>
          <!-- PENDING -->
          <div v-else class="item-double relative">
            <div class="date mt-4 diffprice val">
              distance<!-- {{ calcPendingValueDiff(position, true) }} -->
            </div>
            <div class="date mt--4 mt-4 diffprice">
              {{ calcDiffPricePercent(position, true) }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:4>
        <template v-if="!isMobile">
          <!-- HISTORY -->
          <div v-if="isHistory" class="item close currency">
            {{ calcClosePrice(position) }}
          </div>
          <!-- PENDING/OPEN -->
          <Icon
            v-if="!isHistory && !position.take_profit_price"
            name="pen"
            width="12"
            height="12"
            class="pen-icon"
            title="edit"
            @click="setPositionBehavior('profit')"
          />
          <div
            v-if="!isHistory && position.take_profit_price"
            class="item currency"
          >
            {{ calcTakeProfitPrice(position) }}
          </div>
        </template>
        <template v-else>
          <div
            v-if="
              $isLoggedIn && ['opened', 'proposed'].includes(position.state)
            "
            class="close-position"
            @click.stop="closePosition(position)"
          >
            <icon name="cancel" :width="12" :height="12" class="close" />
          </div>
        </template>
      </template>
      <template v-slot:5>
        <!-- HISTORY -->
        <div v-if="isHistory" class="item-double">
          <div class="date mt-4 currency">{{ calcClosedUp(position) }}</div>
          <div class="gray mt-4 currency">{{ calcClosedDown(position) }}</div>
        </div>
        <!-- PENDING/OPEN -->
        <Icon
          v-if="!isHistory && !position.stop_loss_price"
          name="pen"
          width="12"
          height="12"
          class="pen-icon"
          title="edit"
          @click="setPositionBehavior('loss')"
        />
        <div
          v-if="!isHistory && position.stop_loss_price"
          class="item currency"
        >
          {{ calcStopLossPrice(position) }}
        </div>
      </template>
      <template v-slot:6>
        <!-- HISTORY -->
        <div v-if="isHistory" class="item-double down">
          <template v-if="position.state !== 'canceled'">
            <div
              :class="{
                down:
                  calcPL(position, true, 'netBalance', 'netBalancePct')[0] ===
                  '-',
              }"
              class="mt-4 up currency"
            >
              {{ calcPL(position, true, 'netBalance', 'netBalancePct') }}
            </div>
            <div
              :class="{
                down: calcPlPercent(position, true, 'netBalancePct')[0] === '-',
              }"
              class="centarell-bold up"
            >
              {{ calcPlPercent(position, true, 'netBalancePct') }}%
            </div>
          </template>
        </div>
        <div v-if="isHistory && position.state === 'canceled'" class="item">
          {{ $t('order.history.canceled') }}
        </div>
        <!-- PENDING/OPEN -->
        <div
          v-if="position.state === 'opened'"
          :class="{
            increase: hasPlIncreased(position.id),
            decrease: hasPlDecreased(position.id),
          }"
          class="item-double relative"
        >
          <div
            :class="{
              down: calcPL(position, true)[0] === '-',
            }"
            class="currency mt-4 up"
          >
            {{ calcPL(position, true) }}
          </div>
          <div
            :class="{
              down: calcPlPercent(position, true)[0] === '-',
            }"
            class="centarell-bold up"
          >
            {{ calcPlPercent(position, true) }}%
          </div>
        </div>
        <div v-if="position.state === 'proposed'" class="item-double relative">
          <div class="date mt-4 diffprice val">
            distance
            <!-- {{ calcPendingValueDiff(position, true) }} -->
          </div>
          <div class="date mt--4 mt-4 diffprice">
            {{ calcDiffPricePercent(position, true) }}
          </div>
          <!-- <div class="date mt-4">{{ calcCreatedUp(position) }}</div>
          <div class="gray mt-4 stop">{{ calcCreatedDown(position) }}</div> -->
        </div>
      </template>
      <template v-slot:7>
        <div
          v-if="$isLoggedIn && ['opened', 'proposed'].includes(position.state)"
          class="close-position"
          @click.stop="closePosition(position)"
        >
          <icon name="cancel" :width="12" :height="12" class="close" />
        </div>
      </template>
    </TableRow>
    <div
      class="positions-table__showmore text-main"
      v-if="positions.list.length > activeIndex"
      @click="() => onChangeTabIndex(activeIndex + 8)"
    >
      SHOW MORE
    </div>
  </div>
</template>
<script>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { setRouteFromActiveMarket } from '@/helpers/activeMarketRoute';
import TableRow from '@/components/ui/TableRow';
import '@/assets/icons/pen';
import Icon from '@/components/ui/Icon';
import usePositionCalcs from '@/compositions/usePositionCalcs';
import useChangeFilter from '@/compositions/useChangeFilter';
import useAnimationState from '@/compositions/useAnimationState';
import useUserActions from '@/compositions/useUserActions';
import '@/assets/icons/sell';
import '@/assets/icons/buy';
import '@/assets/icons/sell_simple';
import '@/assets/icons/buy_simple';
import '@/assets/icons/cancel';
import { PositionSides } from '@/config/constants';
import { PositionTypes } from '../helpers';
import { marketTicker } from '@/config/wrapMarkets';
import { useModals } from '@/modules/modals/api';
import { scope } from '@/breakpoints';
import { shortBigNumber } from '@/helpers/utils';
import sortBy from 'lodash/sortBy';
import authMixin from '@/mixins/auth.mixin';

export default {
  name: 'PositionsListItem',
  mixins: [authMixin],
  components: { TableRow, Icon },
  emits: ['closePosition'],
  props: {
    sleepLoading: {
      type: Boolean,
      default: false,
    },
    dataset: {
      type: Object,
      required: true,
    },
    positions: {
      type: Object,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
    },
    grid: {
      type: String,
      default: '',
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLastPosition: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { showModal, modalsByName } = useModals();
    const userActions = useUserActions();
    const isHistory = computed(
      () => props.filterType === PositionTypes.history
    );

    const calcDiffPricePercent = (pos, parseBigNumber) => {
      if (pos.type === 'pending') {
        let percent;

        if (pos.entry_price < pos.closed_price) {
          percent = ((1 - pos.entry_price / pos.closed_price) * 100).toFixed(2);
        } else {
          percent = ((pos.entry_price / pos.closed_price - 1) * 100).toFixed(2);
        }
        if (percent >= 0) {
          if (parseBigNumber) {
            return `${shortBigNumber(percent)}%`;
          }
          return `${percent}%`;
        }

        if (parseBigNumber) {
          return shortBigNumber(percent) + '%';
        }
        return percent + '%';
      }
    };

    const isOpen = computed(() => props.filterType === PositionTypes.open);

    const setCurrentPosition = (position) => {
      store.dispatch('positions/setCurrentPosition', { position });

      if (position.is_bounty) {
        const phoneConfirmed =
          store.getters['settings/settings']?.phoneNumberConfirmed;

        if (!phoneConfirmed) {
          // return store.dispatch('burger/phoneVerification', { auto: false });
        }

        return showModal(modalsByName.positionCard);
      }

      if (!userActions.isEmailVerified()) {
        // return userActions.notifyEmailRequired();
      }

      showModal(modalsByName.positionCard);
    };

    const openChart = (position) => {
      if (position.market_id) {
        store.commit('markets/UPDATE_ACTIVE_MARKET', {
          id: position.market_id,
        });
        setRouteFromActiveMarket({ stopDefault: true });
        if (scope.isSmall || scope.isMedium || scope.noMatch) {
          localStorage.setItem(
            'mobileRedirectToChartFromPositionState',
            position.state
          );
        }
      }
    };

    const closePosition = (position) => {
      emit('closePosition', position);
    };

    const setPositionBehavior = (type) => {
      localStorage.setItem('positionBehavior', type);
    };

    const { activeIndex, onChangeTabIndex } = useChangeFilter();
    onChangeTabIndex(props.filterType === PositionTypes.history ? 3 : 8);

    const visiblePositions = computed(() =>
      sortBy(props.positions.list.slice(0, activeIndex.value), 'type')
    );

    const { process, hasIncreased, hasDecreased } = useAnimationState({
      fields: ['pl_percent', 'closed_price'],
    });

    const hasPlIncreased = (id) => hasIncreased(id, 'pl_percent');
    const hasPlDecreased = (id) => hasDecreased(id, 'pl_percent');
    const hasClosedIncreased = (id) => hasIncreased(id, 'closed_price');
    const hasClosedDecreased = (id) => hasDecreased(id, 'closed_price');

    watch(props.positions, (positions) => {
      process(positions.list);
    });

    const {
      calcAmount,
      calcEntryPrice,
      calcClosePrice,
      calcTakeProfitPrice,
      calcStopLossPrice,
      calcPlPercent,
      calcPL,
      calcCreatedUp,
      calcCreatedDown,
      calcClosedUp,
      calcClosedDown,
      calcPendingValueDiff,
    } = usePositionCalcs();

    return {
      calcPendingValueDiff,
      calcDiffPricePercent,
      hasPlIncreased,
      hasPlDecreased,
      hasClosedIncreased,
      hasClosedDecreased,
      isHistory,
      isOpen,
      openChart,
      setCurrentPosition,
      // positions calcs
      calcAmount,
      calcEntryPrice,
      calcClosePrice,
      calcStopLossPrice,
      calcTakeProfitPrice,
      calcPlPercent,
      calcPL,
      calcCreatedUp,
      calcCreatedDown,
      calcClosedUp,
      calcClosedDown,

      setPositionBehavior,

      closePosition,
      visiblePositions,
      activeIndex,
      onChangeTabIndex,

      PositionSides,
      marketTicker,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.diffprice {
  font-family: 'Cantarell_Regular';
  font-size: 0.9375rem;

  &.mt--4 {
    margin-top: -4px;
  }

  &.val {
    font-family: 'Cantarell_Regular';
    margin-bottom: 1px;
  }
}

.positions-table {
  &__showmore {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    text-align: center;
    font-size: 13px;
    font-family: Gotham_Pro_Medium;
    opacity: 0.65;
    will-change: opacity;
    transition: opacity 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes pulsate {
  50% {
    opacity: 0.5;
  }
}

.positions-list-item {
  border-bottom-width: 1px;
  padding-left: 4px;
  padding-right: 6px;
  background: $color-white;
  transition: background 2s ease-in-out;
  // transition: all 3s ease-in-out;

  &.pending {
    .item.amount {
      font-family: Cantarell_Regular;
    }

    svg {
      color: #888;
    }

    svg.pen-icon {
      color: #fff;
    }

    div {
      color: #888 !important;
    }

    .icon-tp.buy {
      border-color: #888;
    }
  }

  &.inSleep {
    opacity: 0.1;
    -webkit-animation: pulsate 1s linear infinite;
    animation: pulsate 1s linear infinite;
  }

  &.showLastPositionSell {
    background: #de5e56 !important;
    opacity: 0.6;
    // color: #fff !important;
  }

  &.showLastPositionBuy {
    background: #509f96 !important;
    opacity: 0.6;
    // color: #fff !important;
  }
}

.icon-tp {
  margin-right: auto;
  margin-left: 16px;
  border: 1px solid #de5e56;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding-top: 1px;
  width: 21px;
  height: 22px;
  box-sizing: border-box;

  &.buy {
    border: 1px solid #509f96;
    padding-top: 0;
    padding-bottom: 1px;
  }
}

.side-icon {
  margin-right: auto;
  margin-left: 16px;

  &.mini {
    margin-left: 20px;
  }

  &.mini2 {
    margin-left: 19px;
  }

  &.greenStopLossBorder {
    border: 1px solid #509f96;
    box-sizing: content-box;
    border-radius: 4px;
    padding: 2px;
  }

  &.redStopLossBorder {
    border: 1px solid #de5e56;
    box-sizing: content-box;
    border-radius: 4px;
    padding: 2px;
  }
}

.pending.positions-list-item {
  .side-icon {
    &.redStopLossBorder {
      border: 1px solid #888;
    }
  }

  .icon-tp {
    border: 1px solid #888;
  }
}

.item {
  font-size: 0.9375rem;
  line-height: 0.9375rem;

  &.market {
    margin-left: 4px;
    margin-top: 1px;
    transform: translateY(1.5px);
    transition: all 0.2s ease-in-out;
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent;

    @media (max-width: 1024px) {
      margin-left: 7px;
    }

    &:hover {
      border-bottom: 1px solid $color-black;
    }
  }

  &-double {
    font-size: 0.9375rem;
  }
}

.item-double {
  transition: background-color 0.3s ease-in-out;

  &.decrease {
    background: #de5e5638;
  }

  &.increase {
    background: #509f9642;
  }
}

.currency {
  font-family: Cantarell_Regular;
  transition: background-color 0.3s ease-in-out;

  &.decrease {
    background: #de5e5638;
  }

  &.increase {
    background: #509f9642;
  }
}

.amount {
  font-family: Cantarell_Bold;
}

.gray {
  color: $color-gray;
  margin-top: -3px;

  &.stop {
    margin-top: 3px;
  }

  &.decrease {
    background: #de5e5638;
  }

  &.increase {
    background: #509f9642;
  }
}

.centarell-bold {
  font-family: Cantarell_Bold;
  transition: all 0.4s ease-in-out;

  &.decrease.up {
    background: #de5e5638;
  }

  &.increase.up {
    background: #509f9642;
  }
}

.pen-icon {
  opacity: 0.3;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

.relative {
  position: relative;
  min-width: 71px;
}

.close-position {
  width: 30px;
  height: 28px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-size: 25px;
  display: flex;
  line-height: 22px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  box-sizing: border-box;
  color: #888;
  margin-left: -3px;
  font-family: Gotham_Pro_Medium;

  &:hover {
    // background: rgb(241, 138, 140);
    color: #fff;
  }
}

body.twilight {
  .positions-list-item {
    background: #485490;
  }
}

body.dark {
  .positions-list-item {
    background: rgb(19, 23, 33);
  }
}

@keyframes col {
  100% {
    background: transparent;
  }
}

@media (max-width: 480px) {
  .close-position {
    margin-left: 0;
    margin-right: 13px;
  }
}
</style>
