<template>
  <form
    @submit.prevent
    :class="{
      empty: false,
      windows: isWindows,
    }"
    class="form-position-simple"
  >
    <Selector
      description="SELECT LEVERAGE"
      description-input="EXPAND"
      description-secodary="SELECT % OF MAX AMOUNT"
      :update-value="amountUsd"
      :optional-btn="optionalLeverageBtnTitle"
      :optional-btn-secondary="optionalAmountBtnTitle"
      :items="leverageItems"
      :allow-selecting-non-existing-item="true"
      :secondary-items="percentAmountItems"
      :initial-slider-value="leverage"
      :initial-percent-amount="amountPercent"
      :input-title-label="
        !enableToOpenPosition
          ? isFiorinNullableBalance
            ? 'Wallet depleted'
            : $t('popups.marketInactive.limitReached')
          : 'AMOUNT, USD'
      "
      lefted
      fixed-arrow-width
      show-input-first
      not-reset-input-after-select
      :max-value="+maxPositionAmountUsd"
      :disabled="!activeMarket || !activeMarket.name || !enableToOpenPosition"
      @selectItem="set.setLeverage"
      @selectSecondary="set.setAmountPercent"
      @changeInput="set.setAmountUsd"
      @descriptionClick="onExpand"
    />
  </form>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

import Selector from '@/components/ui/Selector';
import authMixin from '@/mixins/auth.mixin';
import { usePositionForm } from '@/modules/formPosition/use/formComposition';
import { isWindows } from '@/modules/formPosition/use/commonSimple';

export default {
  name: 'FormPositionSimpleForm',
  mixins: [authMixin],
  components: { Selector },
  props: {},
  setup() {
    const store = useStore();

    const {
      form: { amountUsd, leverage, amountPercent },
      set,
      ui: {
        leverageItems,
        percentAmountItems,
        optionalAmountBtnTitle,
        optionalLeverageBtnTitle,
      },

      enableToOpenPosition,
      accountBalance,
      maxPositionAmountUsd,
      activeMarket,
      isFiorinNullableBalance,
    } = usePositionForm(store);

    const isNoteClickable = computed(
      () => true // !amountBtc.value || !amountBtc.value.length
    );

    const onExpand = () => {
      set.setIsExpanded(true);
    };

    return {
      isFiorinNullableBalance,
      isWindows,
      onExpand,
      optionalLeverageBtnTitle,
      optionalAmountBtnTitle,
      maxPositionAmountUsd,
      amountUsd,
      isNoteClickable,
      enableToOpenPosition,
      leverage,
      leverageItems,
      percentAmountItems,
      amountPercent,
      accountBalance,
      activeMarket,
      set,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/base';
@import '@/assets/styles/colors';

.form-position-simple {
  width: 100%;
  padding: 0 16px 0 1.344rem;
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.219rem auto 10px;
  box-sizing: border-box;

  &.windows {
    margin-top: 1.25rem;
  }

  &.empty {
    padding: 0 16px;
  }
}
</style>
