import { formPositionStore, cachedPaths } from './store';
import tokenSelector from '@/modules/formPosition/tokenSelector';

export default function module({ store, options }) {
  if (!store) {
    return;
  }
  store.registerModule('formPosition', formPositionStore);
  store.registerModule('tokenSelector', tokenSelector);

  options.cachedPaths = options.cachedPaths.concat(cachedPaths);
  return options;
}
