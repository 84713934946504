import { connApp, getErrorDetails } from '@/helpers/signalR';
import notify from '@/plugins/notify';

const types = {
  SET_LIQUIDITY: 'SET_LIQUIDITY',
};

const state = {
  liquidityInfo: {},
};

const getters = {
  liquidityInfo: (state) => state.liquidityInfo,
};

const mutations = {
  [types.SET_LIQUIDITY](state, data) {
    state.liquidityInfo = data;
  },
};

const actions = {
  async fetchLiquidityInfo({ commit }) {
    let error;
    let result = await connApp
      .invokeWithRetry('GetLiquidityFunding')
      .catch((err) => {
        error = getErrorDetails(err);
      });

    console.debug('#GetLiquidityFunding result', result);

    if (!error) {
      commit(types.SET_LIQUIDITY, result);
    } else {
      notify({ text: error.message, type: 'info' });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
