<template>
  <Modal :stop-mobile-max-height="true" to-top @close="onClose">
    <Card
      title="Open Position"
      description="Choose an asset to collateralize position"
      class="card"
      :body-class="'card-full'"
    >
      <div
        :class="{
          standalone: wd.navigator.standalone || isTelegram,
        }"
        class="new-bounty"
      >
        <div class="new-bounty__items">
          <div
            class="new-bounty__items-item"
            :class="{
              selected: item.name === activeTokenName,
              last: ndx === tokensList.length - 1,
            }"
            v-for="(item, ndx) in tokensList"
            :key="ndx"
            @click="setActiveToken(item)"
          >
            {{ item.name }}
            <icon
              v-if="item.name === activeTokenName"
              name="check"
              :width="12"
              :height="12"
              :class="{ active: item.name === activeTokenName }"
              class="select-icon"
            />
          </div>
          <!-- <div
            v-if="sliceCount <= 3"
            class="new-bounty__items-item noclick"
            @click="sliceCount = tokensList.length"
          >
            + Other Asset
          </div> -->
          <div class="disclaimer">
            Only assets with sufficient balance are shown. Adjust position size
            or leverage to reduce margin and select assets with smaller balances
          </div>
        </div>
      </div>
      <!-- <Button
        v-if="!isMobile"
        text="BACK"
        type="secondary"
        :class="{
          standalone: wd.navigator.standalone || isTelegram,
        }"
        class="new-bounty__btn"
        @click="onClose"
      /> -->
    </Card>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import '@/assets/icons/check';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';
import { scope } from '@/breakpoints';

export default {
  mixins: [authMixin],
  components: {
    Modal,
    Card,
  },
  setup(_, { emit }) {
    const auth = useAuth();
    const sliceCount = ref(3);
    const store = useStore();

    const tokensList = computed(() => {
      if (!auth.isFiorin.value) {
        return [];
      }

      return store.getters['tokenSelector/fitTokens'];
    });

    const activeTokenName = computed(() => {
      if (!auth.isFiorin.value) {
        return '';
      }

      return store.getters['tokenSelector/collateralToken/name'];
    });

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const isMobile = computed(
      () => scope.isSmall || scope.isMedium || scope.noMatch
    );

    const onClose = () => {
      emit('close');
    };

    const setActiveToken = async (token) => {
      console.debug('Setting active token #chooseCollateral', token);
      if (!auth.isFiorin.value) {
        console.error(
          'if not fiorin, why use setActiveToken? #chooseCollateral'
        );
        return;
      }

      await store.dispatch(
        'tokenSelector/collateralToken/setTokenId',
        token.tokenId,
        {
          root: true,
        }
      );
      onClose();
    };

    return {
      isMobile,
      wd: window,
      isTelegram,
      tokensList,
      sliceCount,
      activeTokenName,
      setActiveToken,
      onClose,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/panels.scss';

.isMobile {
  .new-bounty {
    max-height: calc(100% - 50px);
  }
}

.isStandAlone {
  .new-bounty {
    max-height: calc(100% - 82px);
  }
}

.new-bounty {
  min-height: 400px;
  font-family: Gotham_Pro_Regular;
  padding-top: 4px;
  width: calc(100% + 15px);
  margin-left: -6px;

  overflow-y: auto;
  margin-bottom: 15px;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  overflow-x: hidden;

  .select-icon {
    position: absolute;
    right: 15px;
    top: 24px;
    opacity: 0;

    g {
      fill: $colorActiveBlue;
    }

    &.active {
      opacity: 1;
    }

    g {
      fill: $colorActiveBlue;
    }
  }

  &.standalone {
    @media screen and (max-width: 410px) {
      max-height: calc(100% - 165px);
    }
  }

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 360px) !important;
    padding-right: 40px;
    padding-left: 40px;
  }

  .disclaimer {
    margin-bottom: 20px;
    margin-top: 24px;
    font-size: 15px;
    line-height: 20px;
    padding: 0 6px;
    color: $colorLabelDark;
  }

  &__items {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
  }

  &__items-item {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: $colorTextDark;
    background: $backgroundPanelColorDark;
    padding: 0 20px;
    font-family: Gotham_Pro_Medium;
    margin-bottom: 1px;
    cursor: pointer;
    // transition: all 0.3s ease;

    &:hover {
      background: $backgroundHoverColorDark;
      color: $colorActiveBlue;

      .select-icon {
        opacity: 1;
        display: initial !important;
      }
    }

    &.last {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.selected {
      background: $backgroundHoverColorDark;
      color: $colorActiveBlue;

      svg.select-icon {
        g {
          fill: $colorActiveBlue;
        }
      }
    }

    &.noclick {
      color: $colorLabelDark;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.total {
      margin-left: -20px;
      margin-bottom: 25px;
      width: calc(100% + 40px);
      padding: 0 30px;

      @media screen and (min-width: 1024px) {
        margin-left: -40px;
        width: calc(100% + 80px);
        padding: 0 50px;
      }
    }
  }

  &__disclaimer {
    font-size: 15px;
    color: $colorLabelDark;
    font-family: Gotham_Pro_Regular;
    line-height: 20px;

    &:last-child {
      margin-top: 24px;
    }

    span {
      font-family: Gotham_Pro_Medium;
      color: $colorLabelDark;
    }

    .learn {
      color: $colorTextDark;
      cursor: pointer;
    }
  }

  &__field {
    width: calc(100% + 15px);
    margin-left: -6px;
    margin-bottom: 24px;
  }

  &__btn {
    margin-top: 30px;
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;

    &.standalone {
      bottom: 82px;
    }

    @media screen and (min-width: 1024px) {
      bottom: 0;
    }

    &.more {
      bottom: 60px;
    }
  }
}

body.light {
  .new-bounty {
    &__items-item {
      background: $backgroundPanelColorLight;
      color: $colorTextLight;

      &:hover {
        background: $backgroundHoverColorLight;
        color: $colorActiveBlue;
      }

      &.selected {
        background: $backgroundHoverColorLight;
      }
    }

    .disclaimer {
      color: #444;
    }

    &__disclaimer {
      color: $colorTextLight;

      span {
        color: #444;
      }

      .learn {
        color: #444;
      }
    }
  }
}
</style>
