import { releaseVersion } from '@/helpers/releaseChecker';

let disableTags = null;
{
  // [Vadim] only for my environment, comment it out when deploying
  // let tagsLine =
  //   '-#main -#SET_WS_CONFIG -#accounts -GetMarketLimitInfo -ensureInValidState -#loadTimeTracker -#frameService -#fiorin -#auth -#dashboard -#Chart -#settings -#onAuthenticated -#BOUNTY -#session -#FiorinConnecto -#POINTS_USER_SETTINGS -pPPPP -#wallet -#auth -#SignalRConnection  -handshake -WebSockets -#refreshUserData -#uiVisibility';
  //
  // disableTags = tagsLine
  //   .split('-')
  //   .map((x) => x.trim().toLowerCase())
  //   .filter((x) => x);
}

const releaseTag = '#v_' + releaseVersion;

const allLogs = [];

let allowLog = process.env.VUE_APP_IS_PRODUCTION !== 'true';

// window.xor32 = () => {
//   allowLog = true;
// };

const bufferLog = (item) => {
  if (allowLog) {
    allLogs.push(item);
  }
};

export function initConsole() {
  let index = 0;

  let enableConsoleDebug = allowLog;

  let prevDate = new Date();

  function getTime() {
    let date = new Date();
    let hr = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    let ms = date.getMilliseconds();

    let diff = date - prevDate;
    prevDate = date;
    let diffStr = diff > 1000 ? `+${diff / 1000}s` : `+${diff}ms`;

    return { time: `${hr}:${min}:${sec}:${ms}`, diff: diffStr };
  }

  {
    console.nativeLog = console.log;
    console.log = function () {
      const args = bufferAndGetNewArgs('log', arguments);
      if (!args) {
        return;
      }
      console.nativeLog(...args);
    };
  }

  {
    console.nativeDebug = console.debug;
    console.debug = function () {
      if (enableConsoleDebug) {
        const args = bufferAndGetNewArgs('debug', arguments);
        if (!args) {
          return;
        }
        console.nativeDebug(...args);
      }
    };
  }

  {
    console.nativeError = console.error;
    console.error = function () {
      const args = bufferAndGetNewArgs('error', arguments);
      if (!args) {
        return;
      }
      console.nativeError(...args);
    };

    console.debugError = function () {
      if (enableConsoleDebug) {
        const args = bufferAndGetNewArgs('error', arguments);
        if (!args) {
          return;
        }
        console.nativeError(...args);
      }
    };
  }

  {
    console.nativeWarn = console.warn;
    console.warn = function () {
      if (arguments.length === 0) {
        return;
      }

      if (
        typeof arguments[0] === 'string' &&
        arguments[0].startsWith('[intlify]')
      ) {
        // Скрыть предупреждения intlify, chunk-vendors о недостающих переводах
        return;
      }

      const args = bufferAndGetNewArgs('warn', arguments);
      if (!args) {
        return;
      }
      console.nativeWarn(...args);
    };
  }

  console.debugWithTime = async function (arg1, arg2, arg3, fn) {
    if (enableConsoleDebug) {
      console.debug('#start', arg1, arg2, arg3);
      const startTime = new Date();
      const result = await fn();
      const endTime = new Date();
      const diff = endTime - startTime;
      console.debug('#finish', arg1, arg2, arg3, '\n #time:', diff, '\n');
      return result;
    } else {
      return await fn();
    }
  };

  console.useDebug = function (value) {
    if (typeof value !== 'boolean') {
      throw new Error('console.useDebug accepts only boolean values');
    }
    enableConsoleDebug = value;
  };

  window.onerror = (...args) => {
    bufferAndGetNewArgs('window.onerror', args);
  };

  window.onunhandledrejection = (...args) => {
    bufferAndGetNewArgs('window.onunhandledrejection', args);
  };

  function fixArguments(args) {
    for (let i = 0; i < args.length; i++) {
      let type = typeof args[i];
      if (type === 'object') {
        const c = args[i];

        if (!c) {
          continue;
        }

        // skip vue component
        if (
          c['$attrs'] ||
          c['$el'] ||
          c['$options'] ||
          c['$root'] ||
          c['$slots'] ||
          c['$vnode']
        ) {
          continue;
        }
        // [Vadim] this is strictly required to clone this object to avoid parallel changes and showing wrong data in the console!!
        args[i] = deepCloneWithoutFunctionsAndNull(c, 20, 10_000);
      }
    }
  }

  function bufferAndGetNewArgs(mode, args) {
    const time = getTime();

    args = Array.from(args);

    fixArguments(args);
    args.push('#DXS');
    args.push(releaseTag);

    const stack = getStack();
    bufferLog({ mode, time, stack, items: [...args] });

    {
      // [Vadim] this is to disable printing to console the logs with specific tags
      if (disableTags) {
        for (let i = 0; i < args.length; i++) {
          let arg = args[i];
          if (typeof arg === 'string') {
            arg = arg.toLowerCase();
            for (let tag of disableTags) {
              if (arg.includes(tag)) {
                return null;
              }
            }
          }
        }
      }
    }

    args.push('#index-' + index++);
    args.push('#' + mode);
    args.push('\n');
    args.push('\n');
    args.push(stack);
    args.push('\n');
    args.push('\n');
    args.push(`${time.time} (${time.diff})`);

    return args;
  }

  function getStack() {
    const args = [];
    try {
      let e = new Error();
      let lines = e.stack.split('\n').slice(4);
      lines[0] = lines[0].slice(1, lines[0].length);
      args.push(lines.join('\n'));
    } catch (e) {
      args.push('Error when getSourceArguments ' + e.message);
    }

    return args.join('\n');
  }

  function deepCloneWithoutFunctionsAndNull(
    obj,
    maxDepth,
    maxKeys,
    currentDepth = 0,
    keyCounter = { count: 0 },
    visited = new WeakMap()
  ) {
    if (obj === null || obj === undefined) {
      return null;
    }

    const t = typeof obj;

    if (t === 'function') {
      return null;
    }

    //  Return primitive values directly
    if (t !== 'object') {
      return obj;
    }

    if (currentDepth > maxDepth) {
      return '[Out of depth limit]';
    }

    // Обработка циклических ссылок
    if (obj && visited.has(obj)) {
      return '[Recursion]';
    }

    let result;

    if (Array.isArray(obj)) {
      result = [];
    } else if (obj instanceof Map) {
      result = new Map();
    } else if (obj instanceof Set) {
      result = new Set();
    } else if (obj instanceof Date) {
      result = new Date(obj);
    } else if (obj instanceof RegExp) {
      result = new RegExp(obj);
    } else {
      result = {};
    }

    visited.set(obj, result);

    for (const key of [
      ...Object.getOwnPropertyNames(obj),
      ...Object.getOwnPropertySymbols(obj),
    ]) {
      if (Object.hasOwnProperty.call(obj, key)) {
        // Stop if the max number of keys is reached
        if (keyCounter.count >= maxKeys) {
          result['[...truncated]'] = '[Out of key limit]';
          break;
        }

        keyCounter.count++;

        const value = deepCloneWithoutFunctionsAndNull(
          obj[key],
          maxDepth,
          maxKeys,
          currentDepth + 1,
          keyCounter,
          visited
        );

        if (value !== null) {
          result[key] = value;
        }
      }
    }

    return result;
  }
}

const serializeRow = (row) => {
  const { mode, time, stack, items } = row;

  // Helper function to escape special characters in strings
  const escapeXml = (unsafe) => {
    return unsafe.replace(/[<>&'"]/g, (char) => {
      switch (char) {
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '&':
          return '&amp;';
        case "'":
          return '&apos;';
        case '"':
          return '&quot;';
        default:
          return char;
      }
    });
  };

  const serializeObject = (mode, obj) =>
    `\n\n <object type="${mode}">\n${escapeXml(obj)}\n\n </object>\n\n`;

  const formattedArgs = items
    .map((item) => {
      if (typeof item === 'object') {
        try {
          const obj = escapeXml(JSON.stringify(item, null, 2));
          return serializeObject('json', obj);
        } catch {
          return '<object>[Object - error when serialize]</object>';
        }
      }

      if (typeof item === 'string') {
        return escapeXml(item);
      }

      return escapeXml(String(item));
    })
    .join(' ');

  return `<log
 type="${escapeXml(mode)}"
 time="${time.time}"
 timeDiff="${time.diff}">
 \n\n
 <message>${formattedArgs}\n</message>
 \n<stack>\n${escapeXml(stack)}\n</stack>
 \n
 </log>`;
};
const serializeLogs = (logs) => {
  return (
    '<root>\n' +
    logs.map((row) => serializeRow(row)).join('\n\n\n') +
    '\n</root>'
  );
};

const downloadStringAsFile = (fileName, fileContent) => {
  const blob = new Blob([fileContent], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('download', fileName);
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const downloadLogs = () => {
  if (!allowLog) {
    return;
  }

  const text = serializeLogs(allLogs);
  const fileName =
    'dxs-log-' + new Date().toISOString().replace(/:/g, '-') + '.txt';
  downloadStringAsFile(fileName, text);
};

if (allowLog) {
  // [Vadim] this way we can call downloadLogs from the console, just in case.
  // Not in production
  window.downloadLogs = downloadLogs;
}
