<template>
  <div
    style="
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      height: 100%;
      width: 100%;
      top: 0;
    "
  ></div>
</template>
<script setup></script>

<style scoped lang="scss"></style>
