<template>
  <div :key="activeIndex" class="positions-tabs">
    <div
      v-for="(tab, ndx) in tabsItems"
      :key="ndx"
      class="positions-tabs__item"
      @click="selectTab(ndx)"
    >
      <TabButton
        max
        :active="activeIndex === ndx"
        :pl-tab-value="!!plTabValue && ndx === 0"
        :profit="!!plTabValue && ndx === 0 && plTabValue.includes('+$')"
        :title="
          !!plTabValue && ndx === 0
            ? plTabValue
            : `${tab.title} ${
                ndx === 0 ? (!$isLoggedIn ? 8 : userTradeInfo.c || 0) : ''
              }`
        "
        @click="selectTab(ndx)"
      />
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import useChangeFilter from '@/compositions/useChangeFilter';
import { useI18n } from 'vue-i18n';
import { PositionTypes } from '../helpers';
import TabButton from '@/components/ui/TabButton.vue';
import authMixin from '@/mixins/auth.mixin';

export default {
  mixins: [authMixin],
  components: {
    TabButton,
  },
  props: {
    plTabValue: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    console.log('pPPPP', props.plTabValue);

    const userTradeInfo = computed(
      () => store.getters['session/userTradeInfo']
    );

    const tabsItems = [
      { title: t('positions.tabs.open'), filter: PositionTypes.open },
      // { title: t('positions.tabs.pending'), filter: PositionTypes.pending },
      { title: t('positions.tabs.history'), filter: PositionTypes.history },
    ];

    const { activeIndex, onChangeTabIndex } = useChangeFilter();

    const selectTab = (ndx) => {
      onChangeTabIndex(ndx);
      emit(
        'change',
        tabsItems[ndx] ? tabsItems[ndx].filter : tabsItems[0].filter
      );
    };

    onMounted(() => {
      const redirectPositionType = localStorage.getItem(
        'mobileRedirectToChartFromPositionState'
      );
      if (redirectPositionType && redirectPositionType !== 'opened') {
        if (redirectPositionType === 'proposed') {
          selectTab(1);
        } else {
          selectTab(2);
        }
      }
      localStorage.removeItem('mobileRedirectToChartFromPositionState');
    });

    return {
      tabsItems,
      activeIndex,
      userTradeInfo,
      onChangeTabIndex,
      selectTab,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.positions-tabs {
  padding: 10px 20px 10px 10px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  font-family: Gotham_Pro_Bold;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1024px) {
    position: relative;
    width: 100%;
    justify-content: space-between;
    padding: 5px 20px;
    padding-left: 22px;
  }

  &__item {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 41px;
    width: 112px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    padding-top: 3px;

    &:last-child {
      margin-left: 10px;
    }

    @media screen and (max-width: 1024px) {
      width: 48%;

      // &:last-child {
      //   margin-left: 10px;
      //   width: calc(50% - 3px);
      // }
    }
  }
}
</style>
