import store from '@/store';
import { isMobile } from '@/breakpoints';
import { isRunningStandaloneAndroid } from '@/helpers/detects';

export default {
  computed: {
    $isMobile() {
      return isMobile();
    },

    $isTelegram() {
      return Boolean(store.getters['telegram/instance']);
    },

    $isStandalone() {
      return (
        window.navigator.standalone ||
        isRunningStandaloneAndroid() ||
        this.$isTelegram
      );
    },
  },

  methods: {},
};
