<template>
  <Modal :stop-mobile-max-height="true" to-top @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.crowdfunding.modalTitle').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="confirm-payment">
        <p v-html="$t('popups.liquidityFundraiser.confirmProvide0')" />
        <p>{{ $t('popups.liquidityFundraiser.confirmProvide') }}</p>
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.liqAmount1')"
          :value="`${amountBsv} ${$isFiorin ? fiorinCurrency : 'BSV'}`"
          class="mt-15"
        />
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.overTime')"
          :value="returnValue"
          class="mt-15"
        />
        <p>{{ $t('popups.liquidityFundraiser.subject') }}</p>
        <liquidityTable :rounds-items="roundsItems" />
        <template v-if="$isFiorin">
          <p>{{ $t('popups.liquidityFundraiser.limitPerUser.usd') }}</p>
        </template>
        <template v-else>
          <p>{{ $t('popups.liquidityFundraiser.limitPerUser') }}</p>
          <p>{{ $t('popups.liquidityFundraiser.limitPerUser1') }}</p>
        </template>
      </div>
      <HandCash
        v-if="$isHandCash"
        :loading="handcashTxLoading"
        :margin-btn-value="amountBsv"
        :margin="amountBsv"
        @onClick="openHandCashPosition"
        class="modal-btn"
      />
      <HandCash
        v-if="$isFiorin"
        :loading="handcashTxLoading"
        :margin-btn-value="amountBsv"
        :margin="amountBsv"
        :fiorin-currency="fiorinToken ? fiorinToken.name : fiorinCurrency"
        is-usd
        @onClick="openFiorinPosition"
        class="modal-btn"
      />
      <div v-if="$isDotWallet" style="width: 210px; margin: 0 0 20px 10px">
        <DotwalletButton
          :address="config.liquidity_pool_account"
          :amount="amountBsv"
          :opreturn="transactionKey.toHex()"
          product-name="Liquidity"
          @error="onError"
          @payment="onPayment"
        />
      </div>
    </Card>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import HandCash from '@/components/HandCashButton';
import { paymentUID } from '@/helpers/utils';
import envConfig from '@/config';
import notify from '@/plugins/notify';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import DotwalletButton from '@/components/DotwalletButton/DotwalletButton';
import { NullDataBuilder } from 'dxs-stas-sdk/dist/script/build/null-data-builder';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';
import ItemLabelValue from '@/components/ui/ItemLabelValue';
import liquidityTable from './LiquidityTable';

const EVENTS = {
  close: 'close',
  successPayment: 'successPayment',
};

export default {
  mixins: [authMixin],
  components: {
    HandCash,
    DotwalletButton,
    Modal,
    Card,
    ItemLabelValue,
    liquidityTable,
  },
  emits: [EVENTS.close, EVENTS.successPayment],
  props: {
    fiorinToken: { required: true },
    amountBsv: { required: true },
    returnValue: { required: true },
    roundsItems: { required: true },
    fiorinCurrency: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const auth = useAuth();
    const store = useStore();
    const handcashTxLoading = ref(false);

    const msg1 = computed(() => {
      let msg = paymentUID();
      return msg;
    });

    const onPayment = () => {
      emit(EVENTS.successPayment);
    };

    const onError = (data) => {
      notify({
        text: data.error || 'Moneybutton: unknown pay error',
        type: 'info',
      });
    };

    const transactionKey = computed(() => {
      return new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(msg1.value),
        Buffer.from('text'),
        Buffer.from('L'),
      ]);
    });

    const config = computed(() => store.getters['user/wsConfig']);
    const fiorinLastTx = computed(() => store.getters['fiorin/fiorinLastTx']);

    watch(
      () => fiorinLastTx.value,
      async () => {
        if (fiorinLastTx.value) {
          emit(EVENTS.successPayment);
          store.dispatch('wallet/fetchBalance');
          handcashTxLoading.value = false;

          // await connApp.invoke('ProposeLiquidityFunding', {
          //   TransactionId: fiorinLastTx.value,
          //   Amount: props.amountBsv,
          //   Currency: 'USD',
          // });
          store.dispatch('fiorin/setFiorinLastTxId', '');
        }
      }
    );

    const openFiorinPosition = async () => {
      if (!auth.isFiorin.value) {
        throw new Error('It is not Fiorin when openFiorinPosition');
      }

      if (handcashTxLoading.value) {
        return;
      }

      if (!props.fiorinToken) {
        console.error('props.fiorinToken is null #ConfirmPaymentModal');
      }

      handcashTxLoading.value = true;
      const msg = `${envConfig.txPrefix} ${msg1.value} text L`;

      // todo: [Vadim] verify
      store.dispatch('fiorin/refill', {
        // todo: [Vadim] is it possible when fiorinToken is null?
        tokenId: props.fiorinToken
          ? props.fiorinToken.tokenId
          : store.getters['fiorin/maxBalanceToken/tokenId'] || '',
        amount: +props.amountBsv,
        message: msg,
        type: 'POOL',
      });
    };

    const openHandCashPosition = async () => {
      handcashTxLoading.value = true;

      const msg = `${envConfig.txPrefix} ${msg1.value} text L`
        .split(' ')
        .map((item) => Buffer.from(item).toString('hex'));

      const result = await store.dispatch('handcash/sendHandCashTx', {
        msg,
        to: config.value.liquidity_pool_account,
        amount: +props.amountBsv,
        description: ``,
      });

      if (result && result.transactionId) {
        emit(EVENTS.successPayment);
        handcashTxLoading.value = false;
      }

      if (result && result.error) {
        handcashTxLoading.value = false;
        notify({
          text: result.error.message || 'Handcash: unknown pay error',
          type: 'info',
        });
      }
    };

    return {
      handcashTxLoading,
      openHandCashPosition,
      openFiorinPosition,
      onPayment,
      onError,
      config,
      transactionKey,
      EVENTS,
      isRunningStandaloneAndroid,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.confirm-payment {
  font-family: Gotham_Pro_Regular;
  line-height: 18px;
  height: calc(100% - 105px);
  padding-left: $px20;
  padding-right: $px20;
  margin-bottom: 50px;
  overflow-y: auto;

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 280px) !important;
    padding-left: 40px;
    padding-right: 40px;
  }

  &.relay {
    margin-bottom: 25px;
  }

  div {
    margin-top: 15px;
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}
</style>
