export function getLeverageItems(maxLeverage) {
  const max = +maxLeverage;
  const items = [{ title: 1 }];
  if (max <= 10) {
    for (let i = 2; i <= max; i++) {
      items.push({
        title: i,
      });
    }
    return items;
  }

  for (let i = 2; i < 11; i++) {
    items.push({
      title:
        i === 10 ? max : Math.round(+items[items.length - 1].title + max / 10),
    });
  }
  return items;
}

export function getPercentAmountItems() {
  const arr = [10, 25, 50, 70, 80, 100];
  const result = [];
  for (let i = 0; i <= arr.length - 1; i++) {
    result.push({
      title: arr[i],
    });
  }
  return result;
}

export function isWindows() {
  return navigator.appVersion.indexOf('Win') != -1;
}
