<template>
  <Button
    :text="customLabel || `SEND ${marginLabel} ${currencyLabel}`"
    :loading="loading"
    size="medium"
    type="secondary"
    is-html
    :fixState="true"
    class="send-btn"
    @click="$emit('onClick')"
  />
</template>

<script>
import { toCurrencyFormat } from '@/helpers/utils';
import Button from '@/components/ui/Button.vue';
import { computed } from 'vue';

export default {
  components: {
    Button,
  },
  props: {
    currencyLabel: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    marginBtnValue: {
      required: true,
    },
    customLabel: {
      type: String,
      default: '',
    },
    isNotStable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const marginLabel = computed(() => {
      if (props.isNotStable) {
        return props.marginBtnValue;
      }
      return toCurrencyFormat(props.marginBtnValue);
    });

    return { marginLabel };
  },
};
</script>

<style lang="scss" scoped>
.send-btn {
  font-family: Gotham_Pro_Medium;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
