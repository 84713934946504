<template>
  <Card
    title-class="capitalize"
    :description="cardDescription"
    :title="$t('popups.orderCloseConfirm.titleClosePosition')"
  >
    <div class="form-position-confirm">
      <div class="form-position-confirm__main-info">
        <PanelInfoLabelValue
          title="Est. Net P/L, USD"
          :is-red="
            isScalper
              ? false
              : currentPosition.net_pl - positionLoyaltyFeeValue < 0
          "
          :is-green="
            isScalper
              ? false
              : currentPosition.net_pl - positionLoyaltyFeeValue > 0
          "
          :value="
            isScalper
              ? 0
              : formatNetProfitLoss(
                  currentPosition.net_pl - positionLoyaltyFeeValue,
                  currentPosition.base_currency,
                  true
                )
          "
          :large-value="true"
          class="form-position-confirm__stats-field"
        />
        <PanelInfoLabelValue
          :title="`${$t('order.card.titles.amount')}, USD`"
          :value="`${toCurrencyFormat(currentPosition.amount)}`"
          class="form-position-confirm__stats-field"
        />
        <PanelInfoLabelValue
          v-if="currentPosition.state !== 'proposed'"
          :title="$t('order.card.titles.closePrice')"
          :value="
            $t('popups.orderCloseConfirm.marketPrice', {
              price: `${
                currentPosition.side === PositionSides.BUY
                  ? PositionSideTypes.bid
                  : PositionSideTypes.ask
              } ${calcCurrentPrice(currentPosition, markets)}`,
            })
          "
          class="form-position-confirm__stats-field"
        />
        <PanelInfoLabelValue
          v-if="!isPending && +loyaltyFeeRate"
          :title="$t('blocks.titles.loyaltyFee')"
          :hint-options="{ top: '-55px', left: '100px' }"
          :hint-title="$t('blocks.titles.loyaltyFeeHint')"
          :value="`${loyaltyFeeRate.toFixed(2)}%`"
          class="form-position-confirm__stats-field"
        />
      </div>

      <!-- <InfoBlockVertPanel
        v-if="!isPending"
        title="Est. Net P/L, USD"
        :is-red="
          isScalper
            ? false
            : currentPosition.net_pl - positionLoyaltyFeeValue < 0
        "
        :is-green="
          isScalper
            ? false
            : currentPosition.net_pl - positionLoyaltyFeeValue > 0
        "
        :value="
          isScalper
            ? 0
            : formatNetProfitLoss(
                currentPosition.net_pl - positionLoyaltyFeeValue,
                currentPosition.base_currency,
                true
              )
        "
        class="form-position-confirm__stats-total"
      /> -->
      <!-- <InfoBlockVert
        :title="`${$t('order.card.titles.amount')}, USD`"
        :value="`${toCurrencyFormat(currentPosition.amount)}`"
        class="form-position-confirm__stats-field"
      /> -->
      <!-- <InfoBlockVert
        v-if="false"
        :title="$t('order.card.titles.entryPrice')"
        :value="`${
          currentPosition.side === PositionSides.BUY
            ? PositionSideTypes.ask
            : PositionSideTypes.bid
        }  ${calcEntryPrice(currentPosition)}`"
        class="form-position-confirm__stats-field"
      /> -->
      <!-- <InfoBlockVert
        v-if="currentPosition.state !== 'proposed'"
        :title="$t('order.card.titles.closePrice')"
        :value="
          $t('popups.orderCloseConfirm.marketPrice', {
            price: `${
              currentPosition.side === PositionSides.BUY
                ? PositionSideTypes.bid
                : PositionSideTypes.ask
            } ${calcCurrentPrice(currentPosition, markets)}`,
          })
        "
        :lower="true"
        class="form-position-confirm__stats-field"
      /> -->
      <!-- <InfoBlockVert
        v-if="!isPending && +loyaltyFeeRate"
        :title="$t('blocks.titles.loyaltyFee')"
        :hint-options="{ top: '-55px', left: '100px' }"
        :hint-title="$t('blocks.titles.loyaltyFeeHint')"
        :value="`${loyaltyFeeRate.toFixed(2)}%`"
        class="form-position-confirm__stats-field"
      /> -->
      <div
        v-if="
          !isPending &&
          +loyaltyFeeRate &&
          !settings.doNotShowLiquidityFeeWarning
        "
        v-html="$t('order.card.titles.liqFeeHint')"
        class="loyalty-hint"
      />
      <div
        v-if="isScalper"
        v-html="$t('order.card.titles.liqFeeScalping')"
        class="loyalty-hint"
      ></div>
      <div class="form-position-confirm__main-info">
        <Selector
          :description="
            buttonModeIndex === 0 ? 'SELECT % TO CLOSE' : 'SELECT % TO SET STOP'
          "
          optional-btn="BACK"
          fixed-arrow-width
          :show-state-active="true"
          :stop-outside="true"
          :items="selectorValues"
          :value-for-update="valueForUpdate"
          @selectItem="onSelect"
          class="form-position-confirm__selector"
        />
      </div>
      <div class="separator" />
      <div
        :class="{
          standalone: wd.navigator.standalone || isTelegram,
        }"
        class="buttons"
      >
        <Button
          :text="getButtonModeLabels.closeTitle"
          size="medium"
          type="secondary"
          class="confirm-btn"
          :fix-state="buttonModeIndex === 0"
          @click="onConfirm"
        />
        <!-- <Button
          v-if="!closeStopButton.hidden"
          :text="
            currentPosition.stop_loss === closeStopButton.stopValue
              ? $t('popups.orderCloseConfirm.cancelStop')
              : $t('popups.orderCloseConfirm.stopLoss', {
                  percent: closeStopButton.stopValue,
                })
          "
          size="medium"
          type="secondary"
          is-html
          class="confirm-btn"
          @click="setStopLoss(closeStopButton.stopValue)"
        /> -->
        <Button
          :text="getButtonModeLabels.stopTitle"
          size="medium"
          type="secondary"
          :fix-state="buttonModeIndex === 1"
          class="confirm-btn"
          @click="onHalfClose"
        />
      </div>
    </div>
  </Card>
</template>
<script>
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
// import Modal from '@/modules/modals/Modal.vue';
import { toCurrencyFormat } from '@/helpers/utils';
import { formatNetProfitLoss } from '../formaters';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useModals } from '@/modules/modals/api';
import { PositionTypes } from '@/modules/positions/helpers';
import { marketTicker } from '@/config/wrapMarkets';
import useClosePosition from '@/compositions/useClosePosition';
import { connApp } from '@/helpers/signalR';
import notify from '@/plugins/notify';
import usePositionCalcs from '@/compositions/usePositionCalcs';
import { PositionSideTypes } from '@/modules/positions/helpers';
import { PositionSides } from '@/config/constants';

import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';
// import InfoBlockVert from '@/components/ui/InfoBlockVert';
// import InfoBlockVertPanel from '@/components/ui/InfoBlockVertPanel';
import Selector from '@/components/ui/Selector';
import PanelInfoLabelValue from '@/components/ui/PanelInfoLabelValue';
import throttle from 'lodash/throttle';

export default {
  name: 'PositionsCloseConfirm',
  components: {
    Button,
    Card,
    // InfoBlockVert,
    // InfoBlockVertPanel,
    Selector,
    PanelInfoLabelValue,
  },
  mixins: [authMixin],
  setup() {
    const auth = useAuth();
    const store = useStore();
    const { hideModal, modalsByName, modalStatus } = useModals();
    const { closePositionJustNow } = useClosePosition();
    const isScalper = ref(false);

    const { calcClosePrice, calcEntryPrice, calcCurrentPrice } =
      usePositionCalcs();

    const currentPosition = computed(() => {
      return store.getters['positions/getCurrentPosition'];
    });

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    const isPending = computed(() =>
      [PositionTypes.pending, PositionTypes.proposed].includes(
        currentPosition.value.type
      )
    );

    const closeStopButton = computed(() => {
      if (isPending.value || currentPosition.value.pl_percent < -35) {
        return {
          hidden: true,
        };
      }
      return calcStopValue(currentPosition.value.pl_percent);
    });

    const plToStop = {
      0: 0,
      '-15': 20,
      '-35': 40,
    };

    const closeModal = () => {
      hideModal(modalsByName.positionClose);
    };

    const calcStopValue = (pl) => {
      for (const key in plToStop) {
        if (pl > key) {
          return {
            stopValue: plToStop[key],
          };
        }
      }

      return {
        hidden: true,
      };
    };

    const setStopLoss = async (value) => {
      const data = {
        position_id: currentPosition.value.id,
        take_profit: currentPosition.value.take_profit || null,
        stop_loss: value,
        update: true,
      };
      await store.dispatch('positions/changeUserPosition', data);

      closeModal();
    };

    const isScalperPosition = throttle(async (activeMarket) => {
      const currentPrice =
        currentPosition.value.side === 'sell'
          ? activeMarket.buy
          : activeMarket.sell;

      const priceMovementRate =
        (Math.abs(currentPosition.value.entry_price - currentPrice) /
          currentPosition.value.entry_price) *
        100;

      const netPl =
        currentPosition.value.net_pl - positionLoyaltyFeeValue.value;

      const request = {
        positionId: currentPosition.value.id,
        netBalance: netPl,
        priceMovement: priceMovementRate,
      };

      try {
        const allowed = await connApp.invokeWithRetry(
          'GetPositionProfitAllowed',
          request
        );

        console.debug(
          '#GetPositionProfitAllowed #positionsCloseConfirm',
          'request:',
          request,
          'allowed:',
          allowed
        );

        isScalper.value = !allowed;
      } catch (e) {
        console.error(
          '#GetPositionProfitAllowed #positionsCloseConfirm',
          'request:',
          request,
          'error:',
          e
        );
      }
    }, 5_000);

    onMounted(async () => {
      const activeMarket = marketsById.value[currentPosition.value.market_id];

      try {
        await isScalperPosition(activeMarket);
      } catch (_) {
        notify({
          text: 'Server Error',
          type: 'info',
        });
      }

      if (activePosition.value) {
        store.dispatch(
          'volumeLimits/fetchPositionLoyaltyFeeValue',
          activePosition.value.id
        );
      }

      await store.dispatch('volumeLimits/fetchLoyaltyFeeRate', activeMarket.id);
      await store.dispatch('volumeLimits/fetchLoyaltyFeeInfo', activeMarket.id);
    });

    const loyaltyFeeRate = computed(
      () => store.getters['volumeLimits/loyaltyFeeRate']
    );

    const marketsById = computed(() => store.getters['markets/marketsById']);
    const markets = computed(() => store.getters['markets/markets']);

    const valueForUpdate = ref({});

    const onConfirm = async () => {
      if (!auth.isLoggedIn.value) {
        await store.dispatch('burger/login');
        return;
      }

      if (buttonModeIndex.value === 1) {
        buttonModeIndex.value = 0;

        selectorItem.value = selectorValues.value[4];
        onSelect(selectorItem.value);

        valueForUpdate.value = selectorItem.value;
        setTimeout(() => {
          valueForUpdate.value = {};
        }, 50);
        return;
      }

      const percentPosition = parseInt(selectorItem.value.title) / 100;

      if (percentPosition == 1) {
        // close 100%
        await closePositionJustNow();
      } else {
        await connApp.invokeImmediately('SplitAndClosePosition', {
          positionId: currentPosition.value.id,
          splitRatio: percentPosition,
        });
      }

      const historyStats = await connApp.invokeWithRetry(
        'GetClosedPositionsStats'
      );
      store.commit('session/SET_USER_TRADE_INFO_HISTORY', historyStats);
      console.log('historyStats', historyStats);

      const activeMarket = marketsById.value[currentPosition.value.market_id];
      await store.dispatch(
        'volumeLimits/fetchGroupActiveAmount',
        activeMarket.id
      );

      store.dispatch('positions/toggleFlagHalfClose', true);

      closeModal();
    };

    const activeMarket = computed(
      () => marketsById.value[currentPosition.value.market_id]
    );

    watch(
      () => activeMarket.value.usdPrice,
      async (newV) => {
        if (newV) {
          await isScalperPosition(activeMarket.value);
          console.log('CLOSE INFO POSITION', currentPosition.value);
          console.log(
            'CLOSE INFO positionLoyaltyFeeValue',
            positionLoyaltyFeeValue.value
          );
          console.log(
            'CLOSE INFO MARKET',
            marketsById.value[currentPosition.value.market_id]
          );
        }
      }
    );

    const onHalfClose = async () => {
      if (!auth.isLoggedIn.value) {
        await store.dispatch('burger/login');
        return;
      }

      if (buttonModeIndex.value === 0) {
        buttonModeIndex.value = 1;
        selectorItem.value = selectorValues.value[4];
        onSelect(selectorItem.value);

        valueForUpdate.value = selectorItem.value;
        setTimeout(() => {
          valueForUpdate.value = {};
        }, 50);
        return;
      }

      const stopPercent = parseInt(selectorItem.value.title);
      const stopMinus =
        currentPosition.value.pl_percent < 0 ? -stopPercent : stopPercent;
      console.log('convert', currentPosition.value.pl_percent, stopMinus);

      if (
        currentPosition.value.pl_percent < -35 ||
        (stopMinus < 0 && stopMinus > currentPosition.value.pl_percent)
      ) {
        notify({
          text: 'Wrong entry',
          type: 'info',
        });
        return;
      }

      setStopLoss(stopPercent);
    };

    const profitLoss = computed(() => {
      return `${toCurrencyFormat(currentPosition.value.pl)}/${toCurrencyFormat(
        currentPosition.value.pl_percent
      )}%`;
    });

    const activePosition = computed(
      () => store.getters['positions/getCurrentPosition']
    );

    const positionLoyaltyFeeValue = computed(
      () => store.getters['volumeLimits/positionLoyaltyFeeValue']
    );

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const selectorValues = computed(() => {
      let profits = [];

      if (currentPosition.value.net_pl - positionLoyaltyFeeValue.value > 0) {
        profits = [30, 20, 10, 5, 0];
      } else {
        profits = [40, 30, 20, 10, 5];
      }

      if (buttonModeIndex.value === 0) {
        profits = [10, 25, 50, 75, 100];
      }

      return profits.map((item) => {
        return {
          title: item + '%',
        };
      });
    });

    const buttonModeIndex = ref(0);
    const selectorItem = ref(selectorValues.value[4]);

    const getButtonModeLabels = computed(() => {
      if (buttonModeIndex.value === 0) {
        return {
          closeTitle: `CLOSE ${selectorItem.value?.title}`,
          stopTitle: 'SET STOP',
        };
      }

      return {
        closeTitle: 'CLOSE',
        stopTitle: `STOP ${selectorItem.value?.title}`,
      };
    });

    const onSelect = (val) => {
      selectorItem.value =
        selectorValues.value.find((item) => item.title == val?.title) || {};
    };

    const cardDescription = computed(() => {
      const side =
        currentPosition.value.side.toLowerCase() === 'buy' ? 'long' : 'short';

      return `${marketTicker(
        currentPosition.value.market
      )} ${side}, crediting your wallet`;
    });

    return {
      selectorValues,
      wd: window,
      isTelegram,
      settings,
      currentPosition,
      closeStopButton,
      formatNetProfitLoss,
      toCurrencyFormat,
      modalStatus,
      isPending,
      modalsByName,
      onConfirm,
      onHalfClose,
      setStopLoss,
      closeModal,
      profitLoss,
      positionLoyaltyFeeValue,
      loyaltyFeeRate,
      marketTicker,
      isScalper,
      PositionSideTypes,
      PositionSides,
      markets,
      buttonModeIndex,
      getButtonModeLabels,
      cardDescription,
      calcClosePrice,
      calcEntryPrice,
      calcCurrentPrice,
      onSelect,
      valueForUpdate,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-position-confirm {
  display: flex;
  flex-direction: column;
  min-height: 410px;
  width: 100%;

  .form-position-confirm__selector {
    @media screen and (max-width: 1024px) {
      position: absolute;
      width: calc(100% - 26px);
      left: 13px;
      bottom: 130px;
    }
  }

  &__selector {
    margin: 25px 0;
  }

  &__main-info {
    border-radius: 6px;
    overflow: hidden;
    width: calc(100% + 15px);
    margin-left: -6px;
  }

  &__stats-total {
    width: 100%;
    margin-left: -20px;
    margin-bottom: 25px;
    width: calc(100% + 40px);
    padding: 15px 20px;

    @media screen and (min-width: 1024px) {
      margin-left: -40px;
      width: calc(100% + 80px);
      padding: 15px 40px;
    }
  }

  &__stats-field {
    margin-bottom: 1px;

    &:last-child {
      margin-bottom: 0;
    }

    &.mt24 {
      margin-top: 24px;
    }

    &.mb-24 {
      margin-bottom: 24px;
    }
  }

  .loyalty-hint {
    margin: 7px 0 20px;
    font-family: 'Gotham_Pro_Regular';
  }

  &__description {
    margin: 15px 0;
    font-family: Gotham_Pro_Regular;
    font-size: 15px;

    &.less-then {
      margin-top: -5px;
      margin-bottom: 20px;
    }
  }

  .separator {
    margin-top: 45px;
  }

  .buttons {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    bottom: 50px;
    z-index: 1;

    &.standalone {
      bottom: 82px;
    }

    @media screen and (min-width: 1024px) {
      bottom: 0;
    }

    .confirm-btn {
      margin: 0 1px;

      &:first-child {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
