<template>
  <div
    class="app-overlay"
    :style="{
      top: top || 0,
      right: right || 0,
      bottom: bottom || 0,
      left: left || 0,
      maxHeight,
      ...blockStyles,
    }"
  ></div>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    top: {
      type: String,
      default: '0px',
    },
    right: {
      type: String,
      default: '0px',
    },
    bottom: {
      type: String,
      default: '0px',
    },
    left: {
      type: String,
      default: '0px',
    },
    maxHeight: {
      type: String,
      default: 'unset',
    },
    isVisible: Boolean,
  },
  computed: {
    blockStyles() {
      return {
        maxHeight: this.isVisible ? this.maxHeight : 0,
        backgroundColor: this.isVisible
          ? 'rgba(0, 0, 0, 0.6)'
          : 'rgba(0, 0, 0, 0)',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.app-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  will-change: background-color;
  transition: background-color ease 0.25s 0.2s;
  inset: auto !important;
}
</style>
